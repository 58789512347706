import { toast } from 'react-toastify'

import { TranslationHelper } from '@/context/Locale'
import { TechnicalColumnDto } from '@/endpoints/models'
import { ConfirmationOptions } from '@/pages/App/types'
import {
	fetchTaskProgress,
	postTaskType,
} from '@/pages/User/pages/Settings/pages/TechnicalColumns/api'

interface UpdateAndProcessTechnicalColumnsProps {
	confirmModal: (
		options: ConfirmationOptions,
	) => Promise<ConfirmationOptions | null>
	postProcess: (technicalColumn: any | null) => void
	t: TranslationHelper
	technicalColumn: TechnicalColumnDto
	token: string | null
}

export const updateAndProcessTechnicalColumns = async ({
	confirmModal,
	technicalColumn,
	token,
	t,
	postProcess,
}: UpdateAndProcessTechnicalColumnsProps) => {
	try {
		const response = await postTaskType('UPDATE_TECHNICAL_COLUMNS', token, {
			...technicalColumn,
		})

		let taskRunning = true

		while (taskRunning) {
			try {
				const progressResponse = await fetchTaskProgress(response.id, token)

				const progress = progressResponse.progress

				confirmModal({
					title: t('PROCESSING_TASK').toUpperCase(),
					description: t('TASK_RUNNING_WAIT'),
					isAsync: true,
					progress: progress,
				})
				if (progressResponse.state === 'ERROR') {
					toast(t('TASK_START_ERROR'), { type: 'error' })
					taskRunning = false
					postProcess(technicalColumn)
				}
				if (progressResponse.state === 'FINISHED') {
					// Stop the loop once the task is no longer running
					toast(t('TASK_FINISHED'), {
						type: 'success',
					})
					taskRunning = false
					postProcess(technicalColumn)
				} else {
					await new Promise((resolve) => setTimeout(resolve, 1000)) // Delay for 1 second before retrying
				}
			} catch (error) {
				toast(t('TASK_ERROR'), {
					type: 'error',
				})
				console.error('Error fetching task progress:', error)
			}
		}
	} catch (error) {
		toast(t('TASK_ERROR'), {
			type: 'error',
		})
		console.error('Confirmation process failed:', error)

		try {
			const retryResponse = await postTaskType(
				`UPDATE_TECHNICAL_COLUMNS`,
				token,
				{
					...technicalColumn,
				},
			)
			postProcess(retryResponse)
		} catch (retryError) {
			console.error('Error during retry:', retryError)
		}
	}
}
