import { useCallback, useMemo } from 'react'
import { computeImpactTCChange, createTechnicalColumn } from 'src/endpoints'

import { useApiRequest } from '@/endpoints/hooks'
import { TechnicalColumnDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { useConfirmation } from '@/pages/App/components/ConfirmationService'
import { updateAndProcessTechnicalColumns } from '@/pages/User/pages/Settings/pages/TechnicalColumns/components/hooks/updateAndProcessTechnicalColumns'

import { postTaskType } from '../../api'

type PostProcess = (
	technicalColumn: TechnicalColumnDto | null | undefined,
) => void

export const useConfirmationTechnicalColumn = () => {
	const { t } = useAppContext()
	const confirmModal = useConfirmation()
	const request = useApiRequest()

	const handleUpdate = async (
		technicalColumn: TechnicalColumnDto,
		postProcess: PostProcess,
	) => {
		const impact = await request(
			computeImpactTCChange(technicalColumn?.id || 0, technicalColumn),
		)

		if (impact.data?.affectedElements?.TABLE) {
			const progress = 0

			const description = (
				<>
					<div>
						{t('TECHNICAL_COLUMN_UPDATE_AFFECTED_MESSAGE', [
							impact.data?.affectedElements?.TABLE || 0,
						])}
					</div>
				</>
			)

			confirmModal({
				catchOnCancel: true,
				title: t('TECHNICAL_COLUMN_UPDATE_CONFIRM_TITLE'),
				description: description,
				isAsync: true,
				progress: progress,
			}).then(async (options) => {
				if (options?.isCanceled) {
					postProcess(technicalColumn)
					return
				}
				return await updateAndProcessTechnicalColumns({
					confirmModal,
					technicalColumn,
					t,
					postProcess,
				})
			})
		} else {
			postTaskType<TechnicalColumnDto>('UPDATE_TECHNICAL_COLUMNS', {
				...technicalColumn,
			}).then((value) => postProcess(value?.data))
		}
	}

	const handleCreate = async (
		technicalColumn: TechnicalColumnDto,
		postProcess: PostProcess,
	) => {
		if (
			technicalColumn.stereotypeIds &&
			technicalColumn.stereotypeIds?.length > 0
		) {
			const description = (
				<>
					<div>{t('TECHNICAL_COLUMN_ADD_TO_TABLES')}</div>
				</>
			)

			confirmModal({
				catchOnCancel: true,
				title: t('TECHNICAL_COLUMN_UPDATE_CONFIRM_TITLE'),
				description: description,
				isAsync: true,
			})
				.then((options) => {
					if (options?.isCanceled) {
						postProcess(technicalColumn)
						return
					}

					request(
						createTechnicalColumn(technicalColumn, {
							updateElements: true,
						}),
					).then((value) => postProcess(value.data))
				})
				.catch(() => {
					request(
						createTechnicalColumn(technicalColumn, {
							updateElements: false,
						}),
					).then((value) => postProcess(value.data))
				})
		} else {
			await request(
				createTechnicalColumn(technicalColumn, {
					updateElements: false,
				}),
			).then((value) => postProcess(value.data))
		}
	}

	const onConfirmationTechnicalColumn = useCallback(
		async (technicalColumn: TechnicalColumnDto, postProcess: PostProcess) => {
			if (technicalColumn?.id) {
				await handleUpdate(technicalColumn, postProcess)
			} else {
				await handleCreate(technicalColumn, postProcess)
			}
		},
		[confirm, request, t],
	)

	return useMemo(
		() => ({ onConfirmationTechnicalColumn }),
		[onConfirmationTechnicalColumn],
	)
}
