import { useMemo } from 'react'

import { TranslationHelper } from '@/context/Locale'
import { PropertyType } from '@/enums'

import { useDiagramContext } from '../context'
import { handleInputChange } from '../handlers'

interface UsePropertyContentsProps {
	anyLabelVisible?: boolean
	isEditMode: boolean
	localProperties: {
		displayOptions?: any
		grid?: any
		links?: any
		node?: any
	}
	setLocalProperties: (newValue: any) => void
	t: TranslationHelper
}

export const usePropertyContents = ({
	anyLabelVisible = false,
	isEditMode,
	localProperties,
	t,
	setLocalProperties,
}: UsePropertyContentsProps) => {
	const { grid, node: nodeState, displayOptions, links } = localProperties || {}
	const { diagramRef } = useDiagramContext()

	return useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_HEADER_COLOR'),
				contentValue: (
					<input
						type="color"
						value={nodeState?.header?.color}
						onChange={(e) =>
							handleInputChange({
								diagramRef,
								inputType: 'NODE_HEADER_INPUT',
								newValue: {
									node: { header: { color: e.target.value } },
								},
								setLocalProps: setLocalProperties,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Table,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_COLUMNS_BACKGROUND'),
				contentValue: (
					<input
						type="color"
						value={nodeState?.body?.color}
						onChange={(e) =>
							handleInputChange({
								diagramRef,
								inputType: 'NODE_BODY_INPUT',
								newValue: {
									node: { body: { color: e.target.value } },
								},
								setLocalProps: setLocalProperties,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Table,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_CONSTRAINT_COLOR'),
				contentValue: (
					<input
						type="color"
						value={links?.color}
						onChange={(e) =>
							handleInputChange({
								diagramRef,
								inputType: 'LINKS_COLOR_INPUT',
								newValue: {
									links: { color: e.target.value },
								},
								setLocalProps: setLocalProperties,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Constraint,
			},
			{
				id: 4,
				label: t('DIAGRAM_GRID_SWITCH'),
				contentValue: (
					<input
						type="checkbox"
						checked={grid?.isVisible}
						onChange={() =>
							handleInputChange({
								diagramRef,
								inputType: 'GRID_TOGGLE_INPUT',
								newValue: {
									grid: { isVisible: !grid?.isVisible },
								},
								setLocalProps: setLocalProperties,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Grid,
				isGrouped: true,
			},
			...(grid?.isVisible
				? [
						{
							id: 5,
							label: t('DIAGRAM_GRID_CELL_SIZE'),
							contentValue: (
								<input
									type="range"
									min="5"
									max="50"
									value={grid.cellSize || 10}
									onChange={(e) =>
										handleInputChange({
											diagramRef,
											inputType: 'GRID_SIZE_INPUT',
											newValue: {
												grid: { cellSize: Number(e.target.value) },
											},
											setLocalProps: setLocalProperties,
										})
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
							isGrouped: true,
						},
						{
							id: 6,
							label: t('DIAGRAM_GRID_HORIZONTAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={grid.lineColors?.horizontal}
									onChange={(e) =>
										handleInputChange({
											diagramRef,
											inputType: 'GRID_H_LINE_INPUT',
											newValue: {
												grid: { lineColors: { horizontal: e.target.value } },
											},
											setLocalProps: setLocalProperties,
										})
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
						},
						{
							id: 7,
							label: t('DIAGRAM_GRID_VERTICAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={grid.lineColors?.vertical}
									onChange={(e) =>
										handleInputChange({
											diagramRef,
											inputType: 'GRID_V_LINE_INPUT',
											newValue: {
												grid: { lineColors: { vertical: e.target.value } },
											},
											setLocalProps: setLocalProperties,
										})
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
						},
						{
							id: 8,
							label: t('DIAGRAM_GRID_INTERVAL_HORIZONTAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={grid.lineColors?.intervalHorizontal}
									onChange={(e) =>
										handleInputChange({
											diagramRef,
											inputType: 'GRID_INTERVAL_H_LINE_INPUT',
											newValue: {
												grid: {
													lineColors: { intervalHorizontal: e.target.value },
												},
											},
											setLocalProps: setLocalProperties,
										})
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
						},
						{
							id: 9,
							label: t('DIAGRAM_GRID_INTERVAL_VERTICAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={grid.lineColors?.intervalVertical}
									onChange={(e) =>
										handleInputChange({
											diagramRef,
											inputType: 'GRID_INTERVAL_V_LINE_INPUT',
											newValue: {
												grid: {
													lineColors: { intervalVertical: e.target.value },
												},
											},
											setLocalProps: setLocalProperties,
										})
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
						},
					]
				: []),
			{
				id: 10,
				label: t('DIAGRAM_TABLE_CODE'),
				contentValue: (
					<input
						type="checkbox"
						checked={displayOptions?.tableCode}
						onChange={() =>
							handleInputChange({
								diagramRef,
								inputType: 'TABLE_CODE_INPUT',
								newValue: {
									displayOptions: { tableCode: !displayOptions?.tableCode },
								},
								setLocalProps: setLocalProperties,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Display,
			},
			{
				id: 11,
				label: t('DIAGRAM_TABLE_NAME'),
				contentValue: (
					<input
						type="checkbox"
						checked={displayOptions?.tableName}
						onChange={() =>
							handleInputChange({
								diagramRef,
								inputType: 'TABLE_NAME_INPUT',
								newValue: {
									displayOptions: { tableName: !displayOptions?.tableName },
								},
								setLocalProps: setLocalProperties,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Display,
			},
			{
				id: 12,
				label: t('DIAGRAM_TABLE_SHOW_COLUMNS'),
				contentValue: (
					<input
						type="checkbox"
						checked={displayOptions?.tableColumns}
						onChange={() =>
							handleInputChange({
								diagramRef,
								inputType: 'TABLE_COLUMNS_INPUT',
								newValue: {
									displayOptions: {
										tableColumns: !displayOptions?.tableColumns,
									},
								},
								setLocalProps: setLocalProperties,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Display,
			},
			{
				id: 13,
				label: t('DIAGRAM_TABLE_CONSTRAINT_NAME'),
				contentValue: (
					<input
						type="checkbox"
						checked={displayOptions?.hasName}
						onChange={(e) =>
							handleInputChange({
								diagramRef,
								inputType: 'TABLE_CONSTRAINT_NAME_INPUT',
								newValue: {
									displayOptions: { hasName: e.target.checked },
								},
								setLocalProps: setLocalProperties,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Constraint,
			},
		],
		[
			anyLabelVisible,
			displayOptions?.tableCode,
			displayOptions?.tableColumns,
			displayOptions?.tableName,
			displayOptions?.hasName,
			grid?.cellSize,
			grid?.isVisible,
			grid?.lineColors?.horizontal,
			grid?.lineColors?.intervalHorizontal,
			grid?.lineColors?.intervalVertical,
			grid?.lineColors?.vertical,
			handleInputChange,
			isEditMode,
			links?.color,
			nodeState?.body?.color,
			nodeState?.header?.color,
			t,
		],
	)
}
