import { useEffect, useState } from 'react'
import {
	getAllTechnologies,
	getObjectSettingsByType,
	getStereotypesForTemplates,
	getStereotypeTypes,
} from 'src/endpoints'

import { Form, SelectFormField, TextFormField } from '@/components/UberForm'
import { useApi } from '@/endpoints/hooks'
import { StereotypeDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'

import { FilterCell, FilterContainer } from '../../../components/Filter'
import { TemplateFilterProps, TemplateFilterValues } from './types'
import { ELEMENTS_WITH_OBJECT_TYPE } from './utils'

export const TemplateFilter = ({ onChangeFilter }: TemplateFilterProps) => {
	const { t } = useAppContext()
	const technologies = useApi(getAllTechnologies())
	const elements = useApi(getStereotypeTypes())

	const [selectedElement, setSelectedElement] = useState(
		undefined as StereotypeDto.TypeEnum | undefined,
	)

	const objectSettings = useApi(
		getObjectSettingsByType((selectedElement as any) || 'ROOT'),
	)

	useEffect(() => {
		objectSettings.invalidate()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedElement])

	const stereotypes = useApi(
		getStereotypesForTemplates({ type: selectedElement }),
	)

	return (
		<Form<TemplateFilterValues> onChange={onChangeFilter}>
			<FilterContainer>
				<FilterCell>
					<SelectFormField
						title={t('TECHNOLOGY')}
						name="technology"
						options={technologies.data || []}
						fieldIsLoading={technologies.reloading}
						valueKey="id"
						labelKey="name"
						isNumeric
					/>
				</FilterCell>
				<FilterCell>
					<SelectFormField
						title={t('TEMPLATE_ELEMENT')}
						name="structureType"
						options={elements.data || []}
						fieldIsLoading={elements.loading}
						valueKey="code"
						labelKey="code"
						isNumeric
						clearable
						allowEmpty
						onChange={(value) => {
							setSelectedElement(value as StereotypeDto.TypeEnum)
						}}
					/>
				</FilterCell>
				<FilterCell>
					<SelectFormField
						title={t('STEREOTYPE')}
						name="stereotype"
						options={stereotypes.data}
						fieldIsLoading={stereotypes.loading}
						valueKey="id"
						labelKey="name"
						isNumeric
						clearable
						allowEmpty
						disabled={!selectedElement || !stereotypes.data?.length}
					/>
				</FilterCell>
				<FilterCell>
					<SelectFormField
						title={t('OBJECT_TYPE')}
						name="objectSettings"
						options={objectSettings.data}
						fieldIsLoading={objectSettings.loading}
						valueKey="id"
						labelKey="name"
						isNumeric
						clearable
						allowEmpty
						disabled={
							!selectedElement ||
							!ELEMENTS_WITH_OBJECT_TYPE.includes(selectedElement)
						}
					/>
				</FilterCell>
				<FilterCell />
				<FilterCell />
				<FilterCell>
					<TextFormField title={t('TEMPLATE_AUTHOR')} name="authorName" />
				</FilterCell>
				<FilterCell width={58} $maxWidth={58} />
				<FilterCell width={85} $maxWidth={85} style={{ marginRight: 0 }} />
			</FilterContainer>
		</Form>
	)
}
