import { apiRequest } from '@/endpoints/axiosInstance'
import { HttpMethod } from '@/endpoints/utils'
import { PostTaskTypeBody } from '@/pages/User/pages/Settings/pages/TechnicalColumns/types'

export const postTaskType = async <T>(type: string, body: PostTaskTypeBody) => {
	try {
		const response = await apiRequest<T>({
			url: `/task/type/${type}`,
			method: HttpMethod.POST,
			data: body,
		})

		return response
	} catch (error) {
		console.error(error)
	}
}

export const fetchTaskProgress = async <T>(id: number) => {
	try {
		const response = await apiRequest<T>({
			url: `/task/${id}`,
			method: HttpMethod.GET,
		})

		return response
	} catch (error) {
		console.error('Error fetching task progress:', error)
		return null
	}
}
