import { useMemo } from 'react'
import { useImmer } from 'use-immer'

import { PropertyType } from '@/enums'
import { useAppContext, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { RootState } from '@/store'

import { handleInputChange } from '../../handlers'
import { LinkPropertiesProps } from '../../types'
import { DiagramPropertiesContent } from './DiagramPropertiesContent'

export const LinkProperties = ({
	diagramRef,
	propertiesWidth,
	nodeId,
}: LinkPropertiesProps) => {
	const {
		state: { editMode: isEditMode },
	} = useDetailTabContext()

	const nodes = useAppSelector((state: RootState) => state?.node?.nodes)

	const selectedLink = useAppSelector(
		(state) =>
			state.diagram.diagrams[nodeId as number]?.form.graph.inspector
				.inspectedData,
	)

	const thisLink = useAppSelector((state) =>
		state.diagram.diagrams[nodeId as number].form.graph.linkDataArray.find(
			(link) => link.key === selectedLink?.key,
		),
	)

	const { key, text, to, from, linksColor, hasName } = thisLink || {}

	const [linkProperties, setLinkProperties] = useImmer({
		linkColor: linksColor,
		hasName,
	})

	const { t } = useAppContext()

	const contents = useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_ID'),
				contentValue: key,
				type: PropertyType.Info,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_TEXT'),
				contentValue: text,
				type: PropertyType.Info,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_FROM'),
				contentValue: nodes[to as number]?.name,
				type: PropertyType.Info,
			},
			{
				id: 4,
				label: t('DIAGRAM_NODE_TO'),
				contentValue: nodes[from as number]?.name,
				type: PropertyType.Info,
			},
			{
				id: 5,
				label: t('DIAGRAM_NODE_TYPE'),
				contentValue: 'Link',
				type: PropertyType.Info,
			},
			{
				id: 6,
				label: t('DIAGRAM_NODE_CONSTRAINT_COLOR'),
				contentValue: (
					<input
						type="color"
						value={linkProperties.linkColor}
						onChange={(event) =>
							handleInputChange({
								diagramRef,
								inputType: 'SPECIFIC_LINK_COLOR',
								newValue: {
									linkColor: event.target.value,
								},
								setLocalProps: setLinkProperties,
								selectedElKey: selectedLink?.key,
							})
						}
						disabled={!isEditMode}
					/>
				),
				inputType: 'color',
				type: PropertyType.Constraint,
			},
			{
				id: 13,
				label: t('DIAGRAM_TABLE_CONSTRAINT_NAME'),
				contentValue: (
					<input
						type="checkbox"
						checked={linkProperties.hasName}
						onChange={(event) =>
							handleInputChange({
								diagramRef,
								inputType: 'SPECIFIC_LINK_NAME',
								newValue: {
									hasName: event.target.checked,
								},
								setLocalProps: setLinkProperties,
								selectedElKey: selectedLink?.key,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Constraint,
			},
		],
		[
			from,
			nodes,
			isEditMode,
			key,
			linkProperties.hasName,
			linkProperties.linkColor,
			t,
			text,
			to,
		],
	)

	return (
		<DiagramPropertiesContent
			contents={contents}
			propertiesWidth={propertiesWidth}
		/>
	)
}
