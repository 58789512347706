import { useAppContext, useAppDispatch, useAppSelector } from '@/hooks'
import { initDiagram } from '@/store/slices/thunks'

import { useDetailTabContext } from '../../components/DetailTab/context/DetailTabContext'
import { EditableNodeActions } from '../../components/EditableNodeActions/EditableNodeActions'
import { Title } from '../../components/Title/Title'
import { TitleLeftContent } from '../../components/TitleLeftContent'
import { useNodeInit } from '../../hooks/useNodeInit'
import { DiagramTabs } from './components/DiagramTabs'

export const DiagramDetail = () => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const { diagrams } = useAppSelector((state) => state.diagram)

	const {
		state: { node, editMode },
	} = useDetailTabContext()

	const nodeDataArray = useAppSelector(
		(state) => state.diagram.diagrams?.[node.id]?.form.graph.nodeDataArray,
	)

	const deletedNodes = nodeDataArray?.filter((node) => node?.isDeleted)

	const data = diagrams?.[node.id]

	useNodeInit()

	const handleEdit = async () => {
		await dispatch(initDiagram({ nodeId: node.id, editMode: true }))
	}

	const handleCancel = async () => {
		await dispatch(initDiagram({ nodeId: node.id, editMode: false }))
	}

	const DeletedNodesWarning = () => {
		if (deletedNodes?.length) {
			return (
				<div>
					<h3 className="text-amber-500">Warning</h3>
					<p className="py-2.5">{t('DIAGRAM_DELETED_NODES_WARNING')}</p>
					<ul className="list-disc">
						{deletedNodes.map((node) => (
							<li className="pb-2.5 ml-7 text-amber-500" key={node.key}>
								{node.text}
							</li>
						))}
					</ul>
				</div>
			)
		}

		return <></>
	}

	return (
		<>
			<Title
				type={node.type}
				title={node.name}
				editMode={editMode}
				leftContent={<TitleLeftContent node={node} />}
				rightContent={
					<EditableNodeActions
						node={node}
						editMode={editMode}
						dirty={data?.dirty}
						onEdit={handleEdit}
						onCancel={handleCancel}
						customDiagramWarning={<DeletedNodesWarning />}
					/>
				}
			/>
			<DiagramTabs />
		</>
	)
}
