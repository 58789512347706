import { faRocket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'

import { FormWrap, Loader } from '@/components'
import {
	Form,
	Select,
	TextAreaFormField,
	TextFormField,
} from '@/components/UberForm'
import {
	getReleaseEnvironments,
	getSystemRelease,
	updateSystemRelease,
} from '@/endpoints'
import { useApi, useApiRequest } from '@/endpoints/hooks'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useDebounceCallback,
} from '@/hooks'
import { changeTabName } from '@/store/modules/tab/actions'
import { MainContentWrap } from '@/styles'

import { CustomTabDetailProps } from '../../../../types'
import { DpStatusOptions } from '../constants'
import { Header, HeaderContainer } from '../styles'
import { DpStatus, FormData } from '../types'
import { DpStatusModal } from './DpStatusModal'
import { changeSystemReleaseStatus } from './endpoints'
import { DeploymentPackageEnvironments } from './Environments/DeploymentPackageEnvironments'
import { DeploymentPackageStructure } from './Structure/DeploymentPackageStructure'

export const DeploymentPackageDetail = ({
	node,
	selectedTabDetailId,
	isEditMode,
	reloadReleases,
}: CustomTabDetailProps) => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const [formData, setFormData] = useState<FormData | undefined>(undefined)
	const [isStatusModalOpen, setIsStatusModalOpen] = useState(false)

	const [envOption, setEnvOption] = useState<
		'CANCEL' | 'COMPLETED' | 'OPEN' | undefined
	>()

	const dispatch = useAppDispatch()

	const openedTab = useAppStore((store) =>
		store.tab.openedTabs.find((tab) => tab.nodeId === selectedTabDetailId),
	)

	const {
		data: deploymentPkg,
		loading,
		invalidate,
	} = useApi(
		getSystemRelease(
			node.id || (node as unknown as number),
			selectedTabDetailId,
		),
	)

	const updateRequest = useDebounceCallback(async () => {
		if (!formData?.name) {
			return
		}

		await request(
			updateSystemRelease(
				node.id || (node as unknown as number),
				selectedTabDetailId,
				{
					...deploymentPkg,
					...formData,
				},
			),
		)

		reloadReleases?.()
	}, 300)

	const changeStatusHandler = async () => {
		await request(
			changeSystemReleaseStatus(
				node.id,
				deploymentPkg?.id as number,
				envOption,
			),
		)
		invalidate()
	}

	const {
		data: dpEnvironments,
		invalidate: envInvalidate,
		loading: envLoading,
	} = useApi(getReleaseEnvironments(node.id, deploymentPkg?.id as number))

	const getDeploymentPkgHeading = () => {
		if (deploymentPkg?.name) {
			return `${node.name} - ${t('RELEASE_DETAIL')} - ${deploymentPkg?.name}`
		}

		return `${t('RELEASE_DETAIL')} - N/A`
	}

	useEffect(() => {
		invalidate()
	}, [selectedTabDetailId])

	useEffect(() => {
		if (deploymentPkg) {
			setEnvOption(deploymentPkg.status)
		}
	}, [deploymentPkg])

	const onChange = (values: FormData) => {
		dispatch(changeTabName(openedTab?.nodeId as number, values?.name))
		setFormData(values)

		updateRequest()
	}

	const selectOnchange = (value: DpStatus) => {
		setIsStatusModalOpen(true)
		return setEnvOption(value)
	}

	if (loading) {
		return <Loader loaded={false} />
	}

	if (!deploymentPkg) {
		return (
			<MainContentWrap>
				<HeaderContainer>
					<Header>{getDeploymentPkgHeading()}</Header>
				</HeaderContainer>
				{t('RELEASE_NOT_FOUND')}
			</MainContentWrap>
		)
	}

	return (
		<FormWrap>
			<Form<FormData>
				initialValues={{
					name: deploymentPkg?.name,
					description: deploymentPkg?.description,
				}}
				onChange={onChange}
				disabled={!isEditMode}
			>
				<HeaderContainer>
					<FontAwesomeIcon icon={faRocket} color="green" />
					<h1 className="ml-2">{getDeploymentPkgHeading()}</h1>
				</HeaderContainer>

				{/*Name, Status and Description section*/}
				<section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
					<TextFormField
						title={t('NAME')}
						name="name"
						required
						enableAutocomplete={false}
					/>
					<div className="flex items-center flex-col sm:justify-self-end lg:justify-self-start w-full">
						<label className="mb-1 self-start">{t('STATUS')}</label>
						<div className="w-full">
							<Select
								options={DpStatusOptions}
								value={envOption}
								onChange={(value) => selectOnchange(value as DpStatus)}
								clearable={false}
							/>
						</div>
					</div>
					<TextAreaFormField
						title={t('DESCRIPTION')}
						name="description"
						textAreaHeight={29}
						className="xl:pl-2"
					/>

					{isStatusModalOpen && (
						<DpStatusModal
							node={node}
							onConfirm={changeStatusHandler}
							onClose={() => setIsStatusModalOpen(false)}
							type={envOption}
							deploymentPkg={deploymentPkg}
						/>
					)}
				</section>

				<section>
					<DeploymentPackageEnvironments
						node={node}
						deploymentPackage={deploymentPkg}
						editMode={isEditMode}
						dpEnvironments={dpEnvironments}
						invalidate={envInvalidate}
						loading={envLoading}
					/>
				</section>

				<DeploymentPackageStructure
					node={node}
					deploymentPackage={deploymentPkg}
					editMode={isEditMode}
					dpEnvironments={dpEnvironments}
				/>
			</Form>
		</FormWrap>
	)
}
