import { defaultDiagramProperties } from '@/constants'

export const getInitialDiagramData = (name: string, code = '') => ({
	name,
	code,
	graph: {
		nodeDataArray: [],
		linkDataArray: [],
		modelData: {},
		skipsDiagramUpdate: false,
		properties: defaultDiagramProperties,
	},
})
