import { Box, Drawer, Tab, Tabs, Typography } from '@mui/material'

import { DRAWER_WIDTH } from '@/constants'
import { DiagramTab } from '@/enums'
import { useAppContext, useAppSelector } from '@/hooks'

import { useDiagramContext } from '../../context'
import { CustomTabPanelProps, DiagramPropertiesPanelProps } from '../../types'
import { DiagramProperties } from './DiagramProperties'
import { LinkProperties } from './LinkProperties'
import { NodeProperties } from './NodeProperties'

const CustomTabPanel = ({
	children,
	value,
	index,
	...other
}: CustomTabPanelProps) => {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box sx={{ padding: '2rem 1rem' }}>{children}</Box>}
		</div>
	)
}

export const DiagramPropertiesPanel = ({
	isPanelOpen,
}: DiagramPropertiesPanelProps) => {
	const { t } = useAppContext()
	const { diagramRef, node, selectedTab, setSelectedTab } = useDiagramContext()
	const inspector = useAppSelector(
		(state) => state.diagram.diagrams[node?.id as number].form.graph.inspector,
	)

	const a11yProps = (index: number) => {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`,
		}
	}

	const handleChange = (event: any, newValue: DiagramTab) => {
		setSelectedTab?.(newValue)
	}

	return (
		<Drawer
			sx={{
				position: 'absolute',
				top: 0,
				right: 0,
				height: '100%',
				width: DRAWER_WIDTH,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: DRAWER_WIDTH,
					position: 'absolute',
					height: '100%',
				},
			}}
			variant="persistent"
			anchor="right"
			open={isPanelOpen}
		>
			<Box
				sx={{
					padding: '10px 0 0 15px',
					borderBottom: 1,
					borderColor: 'divider',
				}}
			>
				<Typography variant="h6" component="div">
					Properties
				</Typography>
			</Box>

			<Tabs
				value={selectedTab}
				onChange={handleChange}
				aria-label="basic tabs example"
			>
				<Tab label="Diagram" {...a11yProps(0)} />
				<Tab label="Table" {...a11yProps(1)} />
				<Tab label="Link" {...a11yProps(2)} />
			</Tabs>

			<CustomTabPanel value={selectedTab as number} index={0}>
				<DiagramProperties
					diagramRef={diagramRef}
					nodeId={node?.id as number}
				/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTab as number} index={1}>
				{inspector?.inspectedData && inspector?.type === 'node' ? (
					<NodeProperties
						diagramRef={diagramRef}
						propertiesWidth={DRAWER_WIDTH}
						nodeId={node?.id as number}
					/>
				) : (
					<p>{t('DIAGRAM_NODE_PROPERTIES_TEXT')}</p>
				)}
			</CustomTabPanel>
			<CustomTabPanel value={selectedTab as number} index={2}>
				{inspector?.inspectedData && inspector?.type === 'link' ? (
					<LinkProperties
						diagramRef={diagramRef}
						propertiesWidth={DRAWER_WIDTH}
						nodeId={node?.id as number}
					/>
				) : (
					<p>{t('DIAGRAM_LINK_PROPERTIES_TEXT')}</p>
				)}
			</CustomTabPanel>
		</Drawer>
	)
}
