import { ReactDiagram } from 'gojs-react'
import { debounce, merge } from 'lodash'
import { RefObject } from 'react'

import { updateSpecificLink } from '@/components/Diagram/handlers/linkHandlers'

import {
	toggleAllLinkLabels,
	toggleGrid,
	toggleLinksRoutingType,
	toggleTableCode,
	toggleTableColumns,
	toggleTableName,
	updateGridCellSize,
	updateGridLineColors,
	updateLinksColor,
	updateNodeBodyColor,
	updateNodeHeaderColor,
} from './gojsHandlers'
import {
	updateSpecificNodeBodyColor,
	updateSpecificNodeHeaderColor,
} from './nodeHandlers'

interface HandleInputChangeProps {
	diagramRef: RefObject<ReactDiagram> | undefined
	inputType: InputType
	newValue: any
	selectedElKey?: number
	setLocalProps: (newValue: any) => void
}

export type InputType = keyof InputHandlersType

export type InputHandlersType = {
	GRID_H_LINE_INPUT: (newValue: any) => void
	GRID_INTERVAL_H_LINE_INPUT: (newValue: any) => void
	GRID_INTERVAL_V_LINE_INPUT: (newValue: any) => void
	GRID_SIZE_INPUT: (newValue: any) => void
	GRID_TOGGLE_INPUT: (newValue: any) => void
	GRID_V_LINE_INPUT: (newValue: any) => void
	LINKS_COLOR_INPUT: (newValue: any) => void
	NODE_BODY_INPUT: (newValue: any) => void
	NODE_HEADER_INPUT: (newValue: any) => void
	ROUTING_TYPE_INPUT: (newValue: any) => void
	SPECIFIC_LINK_COLOR: (newValue: any) => void
	SPECIFIC_LINK_NAME: (newValue: any) => void
	SPECIFIC_NODE_BODY_COLOR: (newValue: any) => void
	SPECIFIC_NODE_HEADER_COLOR: (newValue: any) => void
	TABLE_CODE_INPUT: (newValue: any) => void
	TABLE_COLUMNS_INPUT: (newValue: any) => void
	TABLE_CONSTRAINT_NAME_INPUT: (newValue: any) => void
	TABLE_NAME_INPUT: (newValue: any) => void
}

const debouncedUpdateSpecificLink = debounce(updateSpecificLink, 500)

export const createInputHandlers = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	selectedElKey?: number,
): InputHandlersType => ({
	// NODE HANDLERS
	SPECIFIC_NODE_HEADER_COLOR: (newValue: any) =>
		updateSpecificNodeHeaderColor(diagramRef, selectedElKey, newValue),

	SPECIFIC_NODE_BODY_COLOR: (newValue: any) =>
		updateSpecificNodeBodyColor(diagramRef, selectedElKey, newValue),

	SPECIFIC_LINK_COLOR: (newValue: any) =>
		debouncedUpdateSpecificLink(
			diagramRef,
			'SPECIFIC_LINK_COLOR',
			selectedElKey as number,
			newValue,
		),

	SPECIFIC_LINK_NAME: (newValue: any) =>
		debouncedUpdateSpecificLink(
			diagramRef,
			'SPECIFIC_LINK_NAME',
			selectedElKey as number,
			newValue,
		),

	// DIAGRAM HANDLERS
	NODE_HEADER_INPUT: (newValue: any) =>
		updateNodeHeaderColor(diagramRef, newValue),

	NODE_BODY_INPUT: (newValue: any) => updateNodeBodyColor(diagramRef, newValue),

	LINKS_COLOR_INPUT: (newValue: any) => updateLinksColor(diagramRef, newValue),

	ROUTING_TYPE_INPUT: (newValue: any) =>
		toggleLinksRoutingType(diagramRef, newValue),

	GRID_TOGGLE_INPUT: (newValue: any) => toggleGrid(diagramRef, newValue),

	GRID_SIZE_INPUT: (newValue: any) => updateGridCellSize(diagramRef, newValue),

	GRID_H_LINE_INPUT: (newValue: any) =>
		updateGridLineColors(diagramRef, newValue, 'GRID_H_LINE_INPUT'),

	GRID_V_LINE_INPUT: (newValue: any) =>
		updateGridLineColors(diagramRef, newValue, 'GRID_V_LINE_INPUT'),

	GRID_INTERVAL_H_LINE_INPUT: (newValue: any) =>
		updateGridLineColors(diagramRef, newValue, 'GRID_INTERVAL_H_LINE_INPUT'),

	GRID_INTERVAL_V_LINE_INPUT: (newValue: any) =>
		updateGridLineColors(diagramRef, newValue, 'GRID_INTERVAL_V_LINE_INPUT'),

	TABLE_CODE_INPUT: (newValue: any) => toggleTableCode(diagramRef, newValue),

	TABLE_NAME_INPUT: (newValue: any) => toggleTableName(diagramRef, newValue),

	TABLE_COLUMNS_INPUT: (newValue: any) =>
		toggleTableColumns(diagramRef, newValue),

	TABLE_CONSTRAINT_NAME_INPUT: (newValue: any) =>
		toggleAllLinkLabels(diagramRef, newValue),
})

export const handleInputChange = ({
	diagramRef,
	inputType,
	newValue,
	setLocalProps,
	selectedElKey,
}: HandleInputChangeProps) => {
	if (!inputType) {
		throw new Error(`Unhandled input type: ${inputType}`)
	}

	const handler = createInputHandlers(diagramRef, selectedElKey)[inputType]

	setLocalProps((draft: any) => {
		merge(draft, newValue)
	})

	return handler(newValue)
}
