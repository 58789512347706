import React from 'react'

import { mapDispatchToProps } from './components/ErrorContainer'

export enum ErrorType {
	HARD,
	SOFT,
}

export type ErrorContainerProps = Readonly<
	ReturnType<typeof mapDispatchToProps> & {
		children: React.ReactNode
	}
>

export type ErrorContainerState = {
	body: string | null
	error: Error | null
	open: boolean
	response?: Response
	timestamp: Date | null
	type: ErrorType
}

export interface ConfirmationOptions {
	catchOnCancel?: boolean
	description: string | React.ReactNode
	isAsync?: boolean
	isCanceled?: boolean
	progress?: number
	title: string
}

export interface ConfirmationDialogProps extends ConfirmationOptions {
	footer: () => React.JSX.Element
	onClose: () => void
	onSubmit: (cancel?: boolean) => void
	open: boolean
}

export type AppProps = {
	token?: string
}
