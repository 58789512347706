import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

import { TranslationHelper } from '@/context/Locale'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import {
	addNode,
	addNodeInDiagramContextMenu,
	addSystemNode,
} from '@/store/modules/node/actions'

export const getAction = (
	data: { data: string; type: StructureObjectDto.TypeEnum },
	parentNode: StructureDto | undefined,
	type: StructureObjectDto.TypeEnum,
	diagramProps: {
		diagramRef: RefObject<ReactDiagram> | undefined
		isDiagramAddModal?: boolean
		node: StructureDto
	},
) => {
	const { diagramRef, isDiagramAddModal, node } = diagramProps || {}

	if (
		type !== StructureObjectDto.TypeEnum.SYSTEM &&
		!diagramProps?.isDiagramAddModal &&
		parentNode
	) {
		return addNode(parentNode.id, data)
	} else if (
		type !== StructureObjectDto.TypeEnum.SYSTEM &&
		diagramProps?.isDiagramAddModal &&
		parentNode
	) {
		return addNodeInDiagramContextMenu(node.parentStructureId as number, data, {
			diagramRef,
			isDiagramAddModal,
			node,
		})
	}

	return addSystemNode(data)
}

export const getHeader = (
	type: StructureObjectDto.TypeEnum,
	t: TranslationHelper,
) => {
	switch (type) {
		case StructureObjectDto.TypeEnum.SYSTEM:
			return t('ADD_NEW_SYSTEM')
		case StructureObjectDto.TypeEnum.FOLDER:
			return t('ADD_NEW_FOLDER')
		case StructureObjectDto.TypeEnum.TABLE:
			return t('ADD_NEW_TABLE')
		case StructureObjectDto.TypeEnum.VIEW:
			return t('ADD_NEW_VIEW')
		case StructureObjectDto.TypeEnum.MAPPING:
			return t('ADD_NEW_MAPPING')
		case StructureObjectDto.TypeEnum.WORKFLOW:
			return t('ADD_NEW_WORKFLOW')
		case StructureObjectDto.TypeEnum.API_NODE:
			return t('ADD_NEW_API_NODE')
		case StructureObjectDto.TypeEnum.API_COLLECTION:
			return t('ADD_NEW_API_COLLECTION')
		case StructureObjectDto.TypeEnum.MAPPING_READ:
			return t('ADD_NEW_MAPPING_READ')
		case StructureObjectDto.TypeEnum.MAPPING_WRITE:
			return t('ADD_NEW_MAPPING_WRITE')
		case StructureObjectDto.TypeEnum.QUEUE:
			return t('ADD_NEW_QUEUE')
		default:
			return undefined
	}
}
