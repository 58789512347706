import { apiRequest } from '@/endpoints/axiosInstance'
import { HttpMethod } from '@/endpoints/utils'

export const lockTemplate = async (id: number) => {
	try {
		const response = await apiRequest({
			url: `/templates/${id}/lock`,
			method: HttpMethod.POST,
		})

		return response
	} catch (error) {
		console.error(error)
	}
}
export const unlockTemplate = async (id: number) => {
	try {
		const response = await apiRequest({
			url: `/templates/${id}/unlock`,
			method: HttpMethod.POST,
		})

		return response
	} catch (error) {
		console.error(error)
	}
}
export const pushTemplate = async (id: number) => {
	try {
		const response = await apiRequest({
			url: `/templates/${id}/push?unlock=true`,
			method: HttpMethod.POST,
		})

		return response
	} catch (error) {
		console.error(error)
	}
}
