import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import styled from 'styled-components'

import { Button } from '@/components'
import { useDiagramContext } from '@/components/Diagram/context'
import { removeDiagramNode } from '@/components/Diagram/handlers'
import { Modal } from '@/components/Modal/Modal'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import { useAppContext, useAppDispatch, useAppStore } from '@/hooks'
import { openOrCloseDeleteModal } from '@/store'
import { deleteNode } from '@/store/modules/node/actions'
import { addDeletedNode } from '@/utils'

interface DeleteModalProps {
	isDiagramAddModal?: boolean
	isOpen?: boolean
	node: StructureDto
	onClose: (removed: boolean) => void
}

export const DeleteModal = ({
	node,
	onClose,
	isDiagramAddModal,
	isOpen = true,
}: DeleteModalProps) => {
	const { t } = useAppContext()
	const { diagramRef } = useDiagramContext()
	const dispatch = useAppDispatch()
	const [secondRound, setSecondRound] = useState(false)
	const { nodeIdToRemove, nodeToDelete } = useAppStore((state) => state.diagram)

	const handleYesClick = async () => {
		if (node.id && !secondRound && node.hasChildren) {
			setSecondRound(true)

			return
		}

		try {
			if (isDiagramAddModal) {
				await dispatch(deleteNode(nodeToDelete as StructureDto))
				dispatch(openOrCloseDeleteModal(false))
				removeDiagramNode(diagramRef, nodeIdToRemove)
				addDeletedNode(nodeIdToRemove)
			} else {
				await dispatch(deleteNode(node))
			}
			onClose(true)
		} catch (e) {
			console.error(e)
		}
	}

	const getHeader = () => {
		switch (node?.type) {
			case StructureObjectDto.TypeEnum.SYSTEM:
				return t('DELETE_SYSTEM')
			case StructureObjectDto.TypeEnum.FOLDER:
				return t('DELETE_FOLDER')
			case StructureObjectDto.TypeEnum.TABLE:
				return t('DELETE_TABLE')
			case StructureObjectDto.TypeEnum.VIEW:
				return t('DELETE_VIEW')
			case StructureObjectDto.TypeEnum.MAPPING:
				return t('DELETE_MAPPING')
			case StructureObjectDto.TypeEnum.MAPPING_READ:
				return t('DELETE_MAPPING_READ')
			case StructureObjectDto.TypeEnum.MAPPING_WRITE:
				return t('DELETE_MAPPING_WRITE')
			case StructureObjectDto.TypeEnum.WORKFLOW:
				return t('DELETE_WORKFLOW')
			default:
				return undefined
		}
	}

	if (!node?.parentStructureId) {
		return <></>
	}

	const getMessage = () => {
		if (!secondRound && isDiagramAddModal) {
			return t('DELETE_NODE_MESSAGE', [nodeToDelete?.name || 'unnamed'])
		} else if (secondRound) {
			return t('DELETE_NODE_MESSAGE_NOT_EMPTY', [node.name || 'unnamed'])
		} else {
			return t('DELETE_NODE_MESSAGE', [node.name || 'unnamed'])
		}
	}

	return (
		<Modal
			open={isOpen}
			contentStyle={{
				width: '400px',
			}}
			header={secondRound ? t('DELETE_NODE_HEADER_NOT_EMPTY') : getHeader()}
			footer={(close) => (
				<>
					<Button icon={faTrash} schema="danger" onClick={handleYesClick}>
						{t('DELETE')}
					</Button>
					<Button schema="transparent" onClick={close} icon={faTimes}>
						{t('NO')}
					</Button>
				</>
			)}
			onClose={() => onClose(false)}
		>
			<PopupText>
				<span>{getMessage()}</span>
			</PopupText>
		</Modal>
	)
}

const PopupText = styled.div`
	display: flex;
	align-items: center;
`
