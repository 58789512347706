import debounce from 'debounce'
import go from 'gojs'
import { useEffect, useRef, useState } from 'react'
import { useImmer } from 'use-immer'

import {
	DiagramControls,
	DiagramModals,
	DiagramPropertiesPanel,
	DiagramWrapper,
} from '@/components/Diagram'
import { useDiagramContext } from '@/components/Diagram/context'
import { useReactDiagramDrop } from '@/components/Diagram/hooks'
import { DiagramContainer } from '@/components/Diagram/styles'
import { DiagramTransaction } from '@/enums'
import { useAppDispatch, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { DiagramState } from '@/pages/User/pages/Home/pages/DiagramDetail/types'
import { changeInspected, diagramUpdated } from '@/store'
import { saveDiagram } from '@/store/slices/thunks'
import { OnModelChange } from '@/types'

export const DiagramGraph = () => {
	const {
		state: { node, editMode },
	} = useDetailTabContext()
	const { diagramRef } = useDiagramContext()

	const dispatch = useAppDispatch()

	const graphData =
		useAppSelector((state) => state.diagram.diagrams[node.id]?.form.graph) || {}

	const instanceDiagram = diagramRef?.current?.getDiagram()

	const { handleDrop, handleDragOver, handleDragLeave } = useReactDiagramDrop()
	const [isPanelOpen, setIsPanelOpen] = useState(false)
	const [diagramState, setDiagramState] = useImmer<DiagramState>({
		nodeDataArray: [],
		linkDataArray: [],
		modelData: undefined,
		skipsDiagramUpdate: true,
	})

	const { nodeDataArray, linkDataArray, modelData, skipsDiagramUpdate } =
		diagramState || {}

	const firstRender = useRef(true)

	// Sync updated state to the backend
	const saveDebounced = debounce(() => {
		const updatePayload = {
			graph: {
				...diagramState,
			},
		}

		dispatch(diagramUpdated({ node, update: updatePayload }))

		dispatch(saveDiagram(node))
	}, 500)

	useEffect(() => {
		saveDebounced()
	}, [diagramState])

	const mapNodeKeyIdxRef = useRef<Map<go.Key, number>>(new Map())
	const mapLinkKeyIdxRef = useRef<Map<go.Key, number>>(new Map())

	/**
	 * Update map of node keys to their index in the array.
	 */
	const refreshNodeIndex = (nodeArr: Array<go.ObjectData>) => {
		mapNodeKeyIdxRef.current.clear()
		nodeArr?.forEach((n: go.ObjectData, idx: number) => {
			mapNodeKeyIdxRef.current.set(n?.key, idx)
		})
	}

	/**
	 * Update map of link keys to their index in the array.
	 */
	const refreshLinkIndex = (linkArr: Array<go.ObjectData>) => {
		mapLinkKeyIdxRef.current.clear()
		linkArr?.forEach((l: go.ObjectData, idx: number) => {
			mapLinkKeyIdxRef.current.set(l?.key, idx)
		})
	}

	const togglePanel = () => setIsPanelOpen(!isPanelOpen)

	/**
	 * Handle any relevant DiagramEvents, in this case just selection changes.
	 * On ChangedSelection, find the corresponding data and set the selectedData state.
	 * @param e a GoJS DiagramEvent
	 */
	const handleDiagramChange = (e: go.DiagramEvent) => {
		const name = e.name

		switch (name) {
			case 'ChangedSelection': {
				const sel = e.subject.first()
				if (sel) {
					if (sel instanceof go.Node) {
						const idx = mapNodeKeyIdxRef.current.get(sel.key)
						if (idx !== undefined && idx >= 0) {
							const nd = nodeDataArray[idx]
							dispatch(
								changeInspected({ data: nd, nodeId: node.id, type: 'node' }),
							)
						}
					} else if (sel instanceof go.Link) {
						const idx = mapLinkKeyIdxRef.current.get(sel.key)
						if (idx !== undefined && idx >= 0) {
							const ld = linkDataArray[idx]
							dispatch(
								changeInspected({ data: ld, nodeId: node.id, type: 'link' }),
							)
						}
					}
				} else {
					dispatch(
						changeInspected({ data: null, nodeId: node.id, type: 'diagram' }),
					)
				}
				break
			}
			default:
				break
		}
	}

	/**
	 * Handle GoJS model changes, which output an object of data changes via Model.toIncrementalData.
	 * This method iterates over those changes and updates state to keep in sync with the GoJS model.
	 * @param diagramModifiedValues a JSON-formatted string
	 */

	const handleModelChange = (diagramModifiedValues: go.IncrementalData) => {
		const {
			modifiedNodeData,
			insertedLinkKeys,
			modifiedLinkData,
			removedNodeKeys,
			insertedNodeKeys,
			removedLinkKeys,
			modelData,
		} = diagramModifiedValues || {}

		const modifiedLinkMap = new Map<go.Key, go.ObjectData>()

		const transactionName =
			instanceDiagram?.undoManager?.currentTransaction?.name

		setDiagramState((draft) => {
			if (modelData) {
				draft.modelData = modelData
			}

			// Handle modified nodes
			if (modifiedNodeData) {
				const isInitialMerge =
					transactionName === DiagramTransaction.InitMerge ||
					transactionName === DiagramTransaction.MergeData

				if (isInitialMerge) {
					console.log(
						`Skipping state mutation for unsupported transaction: ${transactionName}`,
					)
					return
				}

				if (transactionName === DiagramTransaction.UpdateNodeProperties) {
					draft.nodeDataArray = [...modifiedNodeData]
				}

				modifiedNodeData.forEach((modifiedNode: go.ObjectData) => {
					const nodeIndex = mapNodeKeyIdxRef.current.get(modifiedNode.key)

					if (insertedNodeKeys && insertedNodeKeys.includes(modifiedNode.key)) {
						draft.nodeDataArray.push(modifiedNode)
					}

					if (nodeIndex !== undefined && nodeIndex >= 0) {
						draft.nodeDataArray[nodeIndex] = modifiedNode
					}
				})
			}

			if (modifiedLinkData) {
				modifiedLinkData.forEach((modifiedLink: go.ObjectData) => {
					const linkIndex = mapLinkKeyIdxRef.current.get(modifiedLink.key)

					if (insertedLinkKeys && insertedLinkKeys.includes(modifiedLink.key)) {
						draft.linkDataArray.push(modifiedLink)
					}

					if (linkIndex !== undefined && linkIndex >= 0) {
						draft.linkDataArray[linkIndex] = modifiedLink
					}
				})
			}

			// Handle inserted links
			if (insertedLinkKeys) {
				insertedLinkKeys.forEach((key: go.Key) => {
					const ld = modifiedLinkMap.get(key)
					const idx = mapLinkKeyIdxRef.current.get(key)
					if (ld && idx === undefined) {
						mapLinkKeyIdxRef.current.set(ld.key, linkDataArray.length)
					}
				})
			}

			// Handle removed nodes
			if (removedNodeKeys) {
				const filterArray = nodeDataArray.filter(
					(node) => node?.key !== removedNodeKeys[0],
				)

				draft.nodeDataArray = filterArray

				refreshNodeIndex(filterArray)
			}

			if (removedLinkKeys) {
				const filterArray = linkDataArray.filter(
					(link) => link?.key !== removedLinkKeys[0],
				)

				draft.linkDataArray = filterArray

				refreshLinkIndex(filterArray)
			}

			draft.skipsDiagramUpdate = true // the GoJS model already knows about these updates
			firstRender.current = false
		})
	}

	// useEffect to run the refresh functions when nodeDataArray or linkDataArray changes
	useEffect(() => {
		refreshNodeIndex(nodeDataArray)
	}, [nodeDataArray])

	useEffect(() => {
		refreshLinkIndex(linkDataArray)
	}, [linkDataArray])

	useEffect(() => {
		setDiagramState({
			nodeDataArray: graphData?.nodeDataArray,
			linkDataArray: graphData?.linkDataArray,
			modelData: graphData?.modelData,
			skipsDiagramUpdate: graphData?.skipsDiagramUpdate,
		})
	}, [])

	return (
		<>
			<DiagramControls isPanelOpen={isPanelOpen} togglePanel={togglePanel} />
			<DiagramModals />
			<DiagramContainer
				onDrop={editMode ? handleDrop : undefined}
				onDragEnter={handleDragOver}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDragEnd={handleDragLeave}
			>
				<DiagramWrapper
					nodeDataArray={nodeDataArray}
					linkDataArray={linkDataArray}
					modelData={modelData}
					skipsDiagramUpdate={skipsDiagramUpdate}
					onDiagramEvent={handleDiagramChange}
					onModelChange={handleModelChange as OnModelChange}
				/>
				<DiagramPropertiesPanel isPanelOpen={isPanelOpen} />
			</DiagramContainer>
		</>
	)
}
