export enum DiagramTemplateType {
	Link = 'link',
	Table = 'table',
}

export enum TableNodeProps {
	NodeBodyColor = 'nodeBodyColor',
	NodeHeaderColor = 'nodeHeaderColor',
}

export enum DiagramNodeRelationship {
	DIRECT = 'DIRECT',
	REFERENCED = 'REFERENCED',
}

export enum LinkRoutingType {
	Direct = 'direct',
	Orthogonal = 'orthogonal',
}

export enum DiagramTab {
	Diagram = 0,
	Table = 1,
	Link = 2,
}

export enum DiagramTransaction {
	InitMerge = 'initial merge',
	MergeData = 'merge data',
	UpdateDiagramProperties = 'UpdateDiagramProperties',
	UpdateLinkProperties = 'UpdateLinkProperties',
	UpdateNodeProperties = 'UpdateNodeProperties',
}

export enum PropertyType {
	Constraint = 'Constraint',
	Display = 'DisplayOptions',
	Grid = 'Grid',
	Info = 'Info',
	Table = 'Table',
}
