import omit from 'lodash/omit'
import { useMemo } from 'react'

import { TemplateListDto } from '@/endpoints/models'
import { hasObjectAllValues } from '@/utils/collections'

import { TemplateFilterValues } from '../components/types'

export const useTemplatesFilteredData = (
	filter: TemplateFilterValues,
	data: TemplateListDto[] = [],
) => {
	const filteredData = useMemo(() => {
		const dataValues = data.filter((item) => {
			const valuesEqual = hasObjectAllValues(item, omit(filter, 'authorNames'))

			return valuesEqual
		})

		return dataValues
	}, [data, filter])

	return filteredData
}
