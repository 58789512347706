import go, { Routing } from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

import { DiagramTransaction } from '@/enums'

import { DiagramHandler } from '../types'

export const updateDiagramProperty = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	transactionName: string,
	updateFn: (model: go.Model) => void,
) => {
	const diagram = diagramRef?.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction(transactionName)
	updateFn(diagram.model)
	diagram.commitTransaction(transactionName)
}
// Function to update node properties
const updateNodes: DiagramHandler = (
	diagramRef,
	properties,
	transactionName,
) => {
	updateDiagramProperty(
		diagramRef,
		transactionName || DiagramTransaction.UpdateNodeProperties,
		(model) => {
			model.nodeDataArray.forEach((nodeData) => {
				for (const [prop, value] of Object.entries(properties)) {
					model.setDataProperty(nodeData, prop, value)
				}
			})
		},
	)
}

// Function to update link properties
export const updateLinks: DiagramHandler = (diagramRef, properties) => {
	updateDiagramProperty(
		diagramRef,
		DiagramTransaction.UpdateLinkProperties,
		(model) => {
			;(model as go.GraphLinksModel).linkDataArray.forEach((linkData) => {
				for (const [prop, value] of Object.entries(properties)) {
					model.setDataProperty(linkData, prop, value)
				}
			})
		},
	)
}

const updateModelData = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
) => {
	const diagram = diagramRef?.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.model.modelData = newValue
}

// Specific update functions using the generic handlers
export const updateNodeHeaderColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
) => {
	updateModelData(diagramRef, newValue)
	updateNodes(diagramRef, { nodeHeaderColor: newValue.node?.header.color })
}

export const toggleTableCode = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
) => {
	updateModelData(diagramRef, newValue)
	updateNodes(diagramRef, {
		tableCodeVisibility: newValue.displayOptions?.tableCode,
	})
}

export const toggleTableName = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
) => {
	updateModelData(diagramRef, newValue)
	updateNodes(diagramRef, {
		tableNameVisibility: newValue.displayOptions?.tableName,
	})
}

export const toggleTableColumns = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
) => {
	updateModelData(diagramRef, newValue)
	updateNodes(diagramRef, {
		tableColumnsVisibility: newValue.displayOptions?.tableColumns,
	})
}

export const updateNodeBodyColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
) => {
	updateModelData(diagramRef, newValue)
	updateNodes(diagramRef, { nodeBodyColor: newValue.node?.body.color })
}

export const updateLinksColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
) => {
	updateModelData(diagramRef, newValue)
	updateLinks(diagramRef, { linksColor: newValue.links?.color })
}

export const toggleLinksRoutingType = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
) => {
	const diagram = diagramRef?.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram?.model?.startTransaction('change routing type')
	updateModelData(diagramRef, newValue)
	diagram?.links?.each((link) => {
		link.routing =
			newValue.links?.routingType === 'orthogonal'
				? Routing.Orthogonal
				: Routing.Normal
	})
	diagram?.model?.commitTransaction('change routing type')

	updateLinks(diagramRef, { routingType: newValue.links?.routingType })
}

export function toggleAllLinkLabels(
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
) {
	const diagram = diagramRef?.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	const model = diagram.model as go.GraphLinksModel

	model.startTransaction('toggle all link labels')
	updateModelData(diagramRef, newValue)
	model.linkDataArray.forEach((linkData) => {
		model.setDataProperty(linkData, 'hasName', newValue.displayOptions.hasName)
	})
	model.commitTransaction('toggle all link labels')
}

// New toggleGrid function to update the diagram grid visibility
export const toggleGrid = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any = { grid: { isVisible: false } },
) => {
	const diagram = diagramRef?.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction('toggle grid visibility')
	updateModelData(diagramRef, newValue)
	diagram.grid.visible = newValue.grid?.isVisible

	diagram.commitTransaction('toggle grid visibility')
}

export const updateGridCellSize = (
	diagramRef: React.RefObject<ReactDiagram> | undefined,
	newValue: any,
) => {
	const diagram = diagramRef?.current?.getDiagram()
	const size = newValue.grid?.cellSize

	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction('update grid cell size')
	updateModelData(diagramRef, newValue)
	diagram.grid.gridCellSize = new go.Size(size, size)
	diagram.commitTransaction('update grid cell size')
}

export const updateGridLineColors = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	newValue: any,
	type: string,
) => {
	const diagram = diagramRef?.current?.getDiagram()

	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction('update grid colors')

	const updateLineColor = (lineName: string, color: string) => {
		const line = diagram.grid.findObject(lineName) as go.Shape
		if (line && color) {
			line.stroke = color
		}
	}

	switch (type) {
		case 'GRID_H_LINE_INPUT':
			updateModelData(diagramRef, newValue)
			updateLineColor('HorizontalLine', newValue.grid?.lineColors?.horizontal)
			break
		case 'GRID_V_LINE_INPUT':
			updateModelData(diagramRef, newValue)
			updateLineColor('VerticalLine', newValue.grid?.lineColors?.vertical)
			break
		case 'GRID_INTERVAL_H_LINE_INPUT':
			updateModelData(diagramRef, newValue)
			updateLineColor(
				'IntervalHorizontalLine',
				newValue.grid?.lineColors?.intervalHorizontal,
			)
			break
		case 'GRID_INTERVAL_V_LINE_INPUT':
			updateModelData(diagramRef, newValue)
			updateLineColor(
				'IntervalVerticalLine',
				newValue.grid?.lineColors?.intervalVertical,
			)
			break
		default:
			throw new Error(`Unknown line type: ${type}`)
	}

	diagram.commitTransaction('update grid colors')
}
