import * as go from 'gojs'

import { StructureDto } from '@/endpoints/models'
import { DiagramTab } from '@/enums'
import { useAppDispatch } from '@/hooks'
import { openTab } from '@/store/modules/tab/actions'

import { useDiagramContext } from '../../context'
import { getNodeData } from './api'
import { createTableNodeContextMenu } from './contextMenu'

export const useNodeTempSettings = () => {
	const { diagramRef, setSelectedTab } = useDiagramContext()

	const dispatch = useAppDispatch()

	const nodeTempClickHandler = (e: go.InputEvent) => {
		const clickedPart = e.diagram.findPartAt(e.documentPoint)

		// Ensure the clicked part is a Node so we can safely access its specific properties
		if (clickedPart instanceof go.Node) {
			const clickedNode = clickedPart
			console.log('clickedNode', clickedNode)

			const clickedNodeData = clickedNode.data
			console.log('clickedNodeData', clickedNodeData)

			setSelectedTab?.(DiagramTab.Table)
		}

		console.error('No node was clicked.')
	}

	const nodeTempDblClickHandler = async (e: go.InputEvent) => {
		const clickedPart = e.diagram.findPartAt(e.documentPoint)

		//write a fetch function to get data
		const { data } =
			(await getNodeData<StructureDto>(clickedPart?.data.key)) || {}

		if (!data) return
		dispatch(openTab(data, false))
	}

	return {
		locationSpot: go.Spot.Center,
		movable: true,
		contextMenu: createTableNodeContextMenu(dispatch, diagramRef),
		// toolTip: createTableNodeTooltip(),
		click: (e: go.InputEvent) => nodeTempClickHandler(e),
		doubleClick: (e: go.InputEvent) => nodeTempDblClickHandler(e),
	}
}
