import { PureComponent } from 'react'

import { FormFieldProps } from '@/components/UberForm/types'
import { splitProps } from '@/utils/collections'

import FormField, { FormlessFormField } from '../FormField'
import TextArea, { TextAreaProps } from '../Input/TextArea/TextArea'

/**
 * Form field with textarea.
 */
export default class TextAreaFormField extends PureComponent<
	FormFieldProps & Omit<TextAreaProps, 'onChange'> & { formless?: boolean }
> {
	render() {
		const fieldProps = { ...this.props }
		const inputProps = splitProps(fieldProps, [
			'rows',
			'autoFocus',
			'textAreaHeight',
		])

		const FF = this.props.formless ? FormlessFormField : FormField

		return (
			<FF {...fieldProps}>
				<TextArea {...inputProps} />
			</FF>
		)
	}
}
