import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { StructureDto } from '@/endpoints/models'
import { DiagramTab } from '@/pages/User/pages/Home/pages/DiagramDetail/components/enums'
import { StructureDtoRedux } from '@/store/modules/node'
import {
	DiagramState,
	InspectedType,
	OpenedDiagramData,
} from '@/store/slices/diagram/types'
import { getInitialDiagramData } from '@/store/slices/diagram/utils'
import { initDiagram, saveDiagram } from '@/store/slices/thunks'
import { updateTabData } from '@/store/utils'
import { LinkData, NodeData } from '@/types'
import { getDeletedNodes } from '@/utils'

const initialState: DiagramState = {
	asyncStatus: {
		isInitLoading: false,
		initError: '',
		isSaveLoading: false,
		saveError: '',
	},
	diagrams: {} as OpenedDiagramData,
	isAddModalOpen: false,
	isDeleteModalOpen: false,
	nodeToDelete: undefined,
	nodeIdToRemove: 0,
}

const diagramSlice = createSlice({
	name: 'diagram',
	initialState,
	reducers: {
		setNodeIdToRemove: (state, action: PayloadAction<number>) => {
			state.nodeIdToRemove = action.payload
		},
		setNodeToDelete: (state, action: PayloadAction<StructureDtoRedux>) => {
			state.nodeToDelete = action.payload
		},
		openOrCloseDeleteModal: (state, action: PayloadAction<boolean>) => {
			state.isDeleteModalOpen = action.payload
		},
		openOrCloseAddModal: (state, action: PayloadAction<boolean>) => {
			state.isAddModalOpen = action.payload
		},
		diagramUpdated: (
			state,
			action: PayloadAction<{ node: any; update: any }>,
		) => {
			const { node, update } = action.payload

			state.diagrams = updateTabData(state.diagrams, node.id, {
				form: update,
				dirty: true,
			})
		},
		changeInspected: (
			state,
			action: PayloadAction<{
				data: LinkData | Partial<NodeData> | null
				nodeId: number
				type: InspectedType
			}>,
		) => {
			const { data, nodeId, type } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram) {
				diagram.form.graph.inspector = {
					inspectedData: data,
					type,
				}
			}
		},
	},
	extraReducers: (builder) => {
		builder
			// initDiagram cases
			.addCase(initDiagram.pending, (state) => {
				state.asyncStatus.isInitLoading = true
				state.asyncStatus.initError = ''
			})
			.addCase(
				initDiagram.fulfilled,
				(
					state,
					action: PayloadAction<{
						editMode: boolean
						force: boolean
						node: any
					}>,
				) => {
					const { node, editMode } = action.payload
					const previous = state.diagrams[node.id]

					if (previous && editMode && previous.parsedEditMode) {
						return
					}

					const serializedData = editMode
						? node.workingData || node.data
						: node.data
					const parsed = JSON.parse(serializedData as string)
					const original = JSON.parse(node.data as string)

					// Restore nodeDataArray and apply deletion markers
					const updatedNodeDataArray = parsed?.graph?.nodeDataArray?.map(
						(node: NodeData) => {
							const deletedNode = getDeletedNodes().find(
								(deleteNodeId) => deleteNodeId === node.key,
							)
							return deletedNode ? { ...node, isDeleted: true } : node
						},
					)

					state.diagrams[node.id] = {
						form: {
							...getInitialDiagramData(node.name),
							...parsed,
							graph: {
								...getInitialDiagramData(node.name).graph,
								...parsed.graph,
								nodeDataArray: updatedNodeDataArray || [],
							},
						},
						tab: previous ? previous.tab : DiagramTab.Overview,
						dirty: false,
						parsedEditMode: editMode,
						original,
					}
					state.asyncStatus.isInitLoading = false
				},
			)
			.addCase(initDiagram.rejected, (state, action) => {
				state.asyncStatus.isInitLoading = false
				state.asyncStatus.initError =
					action.error.message || 'Failed to initialize diagram'
			})

			// saveDiagram cases
			.addCase(saveDiagram.pending, (state) => {
				state.asyncStatus.isSaveLoading = true
				state.asyncStatus.saveError = ''
			})
			.addCase(
				saveDiagram.fulfilled,
				(state, action: PayloadAction<StructureDto>) => {
					const { id } = action.payload
					if (state.diagrams[id]) {
						state.diagrams[id] = {
							...state.diagrams[id],
							dirty: false,
						}
					}
					state.asyncStatus.isSaveLoading = false
				},
			)
			.addCase(saveDiagram.rejected, (state, action) => {
				state.asyncStatus.isSaveLoading = false
				state.asyncStatus.saveError =
					action.error.message || 'Failed to save diagram'
			})
	},
})

export const {
	diagramUpdated,
	setNodeToDelete,
	openOrCloseDeleteModal,
	setNodeIdToRemove,
	openOrCloseAddModal,
	changeInspected,
} = diagramSlice.actions

export default diagramSlice.reducer
