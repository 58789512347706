import { LinkRoutingType } from '../enums'
import diagramColors from '../styles/diagramColors'

export const defaultDiagramProperties = {
	grid: {
		isVisible: false,
		cellSize: 26,
		lineColors: {
			intervalHorizontal: diagramColors.grid.intervalHorizontalLineColor,
			intervalVertical: diagramColors.grid.intervalVerticalLineColor,
			vertical: diagramColors.grid.verticalLineColor,
			horizontal: diagramColors.grid.horizontalLineColor,
		},
	},
	displayOptions: {
		tableName: true,
		tableCode: false,
		tableColumns: true,
		pkColumnsName: true,
		pkColumnsCode: true,
		columnsName: true,
		columnsCode: true,
		comments: true,
		description: true,
		hasName: true,
	},
	node: {
		header: {
			color: diagramColors.node.header,
		},
		body: {
			color: diagramColors.node.body,
		},
	},
	links: {
		color: '',
		routingType: LinkRoutingType.Orthogonal,
	},
}

export const DRAWER_WIDTH = 300
