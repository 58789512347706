import { ReactDiagram } from 'gojs-react'
import { useEffect } from 'react'

import { useDiagramContext } from '../context'
import { useInitDiagram } from '../hooks'
import { DiagramWrapperProps } from '../types'
import { debugModelData } from '../utils'

export const DiagramWrapper = ({
	linkDataArray,
	skipsDiagramUpdate,
	onDiagramEvent,
	onModelChange,
	nodeDataArray,
	modelData,
}: DiagramWrapperProps) => {
	const { diagramRef } = useDiagramContext()

	const { initDiagram } = useInitDiagram()

	useEffect(() => {
		const goJsDiagram = diagramRef?.current?.getDiagram()
		if (goJsDiagram) {
			goJsDiagram?.addDiagramListener('ChangedSelection', onDiagramEvent)

			// Cleanup function to be called on unmount
			return () => {
				goJsDiagram?.removeDiagramListener('ChangedSelection', onDiagramEvent)
			}
		}
	}, [diagramRef, onDiagramEvent])

	useEffect(() => {
		if (diagramRef?.current) {
			debugModelData(diagramRef)
		}
	}, [diagramRef])

	return (
		<ReactDiagram
			ref={diagramRef}
			divClassName="diagram"
			initDiagram={initDiagram}
			nodeDataArray={nodeDataArray}
			linkDataArray={linkDataArray}
			onModelChange={onModelChange}
			skipsDiagramUpdate={skipsDiagramUpdate}
			modelData={modelData}
		/>
	)
}
