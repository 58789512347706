import styled, { css } from 'styled-components'

export const PropertiesHeader = styled.div`
	border-bottom: 1px solid black;
	font-weight: bold;
	margin: 0 30px 0 30px;
	display: flex;
	flex-direction: row;
	justify-content: center;
`

export const DiagramContainer = styled.div`
	flex: 1;
	position: relative;
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	height: max(400px, 100%);

	.diagram {
		flex: 1 1 auto;
	}
`

export const ValueRow = styled.div<{
	$propertiesWidth: number
	$valueWidth?: string
}>`
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
	padding: 0 10px;
	width: 100%;
	box-sizing: border-box;
	min-height: 30px;
	${(props) =>
		props.$propertiesWidth > 450 &&
		css`
			width: ${props.$valueWidth ? props.$valueWidth : '100%'};
		`}
	${(props) =>
		props.$valueWidth &&
		css`
			min-width: auto;
		`}
`

export const RoutingTypeContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 1rem;
	margin-left: 0.5rem;
`
export const RoutingTypeLabel = styled.label`
	margin-bottom: 0.5rem;
`
export const RadioGroup = styled.div`
	display: flex;
	align-items: center;
`
export const RadioLabel = styled.label`
	margin-right: 1rem;
	display: flex;
	align-items: center;
`
