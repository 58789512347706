import { GraphLinksModel } from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

import { updateDiagramProperty } from './gojsHandlers'

interface LinkLocalProperties {
	hasName: boolean
	linkColor: string
}

export const updateSpecificLink = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	inputType: string,
	linkKey: number,
	updatedValue: LinkLocalProperties,
) => {
	updateDiagramProperty(diagramRef, 'update link color', (model) => {
		// Ensure the model is of type GraphLinksModel
		if (!(model instanceof GraphLinksModel)) {
			console.error('Model is not a GraphLinksModel')
			return
		}

		const linkData = model.linkDataArray.find((link) => link.key === linkKey)
		if (!linkData) {
			console.error('No link found with the specified key')
			return
		}

		switch (inputType) {
			case 'SPECIFIC_LINK_COLOR':
				return model.setDataProperty(
					linkData,
					'linksColor',
					updatedValue.linkColor,
				)

			case 'SPECIFIC_LINK_NAME':
				return model.setDataProperty(linkData, 'hasName', updatedValue.hasName)
			default:
				break
		}
	})
}
