export enum GenerateCodeEnum {
	INHERIT = 'inherit',
	NO = 'false',
	YES = 'true',
}

export enum MassExportSortingEnum {
	DEFAULT = 'DEFAULT',
	SORT_BY_NAME_ASC = 'SORT_BY_NAME_ASC',
	SORT_BY_TYPE_AND_NAME_ASC = 'SORT_BY_TYPE_AND_NAME_ASC',
}

export enum NamingDtoTypeEnum {
	API_COLLECTION_CODE = 'API_COLLECTION_CODE',
	API_NODE_CODE = 'API_NODE_CODE',
	DDL_SCRIPT_NAME = 'DDL_SCRIPT_NAME',
	DEFAULT_MAPPING_CODE = 'DEFAULT_MAPPING_CODE',
	DEFAULT_MAPPING_NAME = 'DEFAULT_MAPPING_NAME',
	DIAGRAM_CODE = 'DIAGRAM_CODE',
	EXPORT_NAME = 'EXPORT_NAME',
	FOLDER_CODE = 'FOLDER_CODE',
	HISTORICAL_DEFAULT_MAPPING_CODE = 'HISTORICAL_DEFAULT_MAPPING_CODE',
	HISTORICAL_DEFAULT_MAPPING_NAME = 'HISTORICAL_DEFAULT_MAPPING_NAME',
	HISTORICAL_TABLE_CODE = 'HISTORICAL_TABLE_CODE',
	HISTORICAL_TABLE_NAME = 'HISTORICAL_TABLE_NAME',
	INTERFACE_CODE = 'INTERFACE_CODE',
	INTERFACE_DEFAULT_MAPPING_CODE = 'INTERFACE_DEFAULT_MAPPING_CODE',
	INTERFACE_DEFAULT_MAPPING_NAME = 'INTERFACE_DEFAULT_MAPPING_NAME',
	MAPPING_CODE = 'MAPPING_CODE',
	PRIMARY_KEY_NAME = 'PRIMARY_KEY_NAME',
	QUEUE_CODE = 'QUEUE_CODE',
	REFERENCE_DEFAULT_MAPPING_CODE = 'REFERENCE_DEFAULT_MAPPING_CODE',
	REFERENCE_DEFAULT_MAPPING_NAME = 'REFERENCE_DEFAULT_MAPPING_NAME',
	REFERENCE_TABLE_CODE = 'REFERENCE_TABLE_CODE',
	REFERENCE_TABLE_NAME = 'REFERENCE_TABLE_NAME',
	SEQUENCE_NAME = 'SEQUENCE_NAME',
	SYSTEM_CODE = 'SYSTEM_CODE',
	TABLE_CODE = 'TABLE_CODE',
	VIEW_CODE = 'VIEW_CODE',
	WORKFLOW_CODE = 'WORKFLOW_CODE',
}

export enum SqlTypeEnum {
	API_COLLECTION = 'API_COLLECTION',
	API_NODE = 'API_NODE',
	DIAGRAM = 'DIAGRAM',
	MAPPING = 'MAPPING',
	MAPPING_READ = 'MAPPING_READ',
	MAPPING_WRITE = 'MAPPING_WRITE',
	QUEUE = 'QUEUE',
	TABLE = 'TABLE',
	TABLE_HISTORY = 'TABLE_HISTORY',
	TABLE_REFERENCE = 'TABLE_REFERENCE',
	VIEW = 'VIEW',
	WORKFLOW = 'WORKFLOW',
}
