import { useEffect } from 'react'
import { useImmer } from 'use-immer'

import { defaultDiagramProperties } from '@/constants'
import { LinkRoutingType } from '@/enums'
import { DiagramPropertiesI } from '@/types'

// Custom hook to sync local state
export const useLocalDiagramProperties = (
	diagramProperties: DiagramPropertiesI,
) => {
	const [localProperties, setLocalProperties] = useImmer(
		defaultDiagramProperties,
	)

	const { grid: defaultGrid } = defaultDiagramProperties

	const { grid, displayOptions, node, links } = diagramProperties || {}

	useEffect(() => {
		setLocalProperties((draft) => {
			// Sync the `grid` properties
			draft.grid = {
				isVisible: grid?.isVisible,
				cellSize: grid?.cellSize,
				lineColors: {
					intervalHorizontal:
						grid?.lineColors?.intervalHorizontal ||
						defaultGrid?.lineColors?.intervalHorizontal,
					intervalVertical:
						grid?.lineColors?.intervalVertical ||
						defaultGrid?.lineColors?.intervalVertical,
					vertical:
						grid?.lineColors?.vertical || defaultGrid?.lineColors?.vertical,
					horizontal:
						grid?.lineColors?.horizontal || defaultGrid?.lineColors?.horizontal,
				},
			}

			// Sync the `displayOptions` properties
			draft.displayOptions = {
				tableName: displayOptions?.tableName || true,
				tableCode: displayOptions?.tableCode || false,
				tableColumns: displayOptions?.tableColumns || true,
				pkColumnsName: displayOptions?.pkColumnsName || true,
				pkColumnsCode: displayOptions?.pkColumnsCode || true,
				columnsName: displayOptions?.columnsName || true,
				columnsCode: displayOptions?.columnsCode || true,
				comments: displayOptions?.comments || true,
				description: displayOptions?.description || true,
				hasName: displayOptions?.hasName || false,
			}

			// Sync the `node` properties
			draft.node = {
				header: {
					color: node?.header?.color,
				},
				body: {
					color: node?.body?.color,
				},
			}

			// Sync the `links` properties
			draft.links = {
				color: links?.color,
				routingType: links?.routingType || LinkRoutingType.Orthogonal,
			}
		})
	}, [])

	return { localProperties, setLocalProperties }
}
