import { createTheme } from '@mui/material/styles'

import { mainColors } from '@/styles/index'

// Define a custom theme with a grey palette
export const theme = createTheme({
	palette: {
		primary: {
			main: '#265560', // Primary main color (blue by default)
		},
		secondary: {
			main: mainColors.secondary, // Secondary main color (purple by default)
		},
	},
})

export default theme
