import { useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

import { PropertyType } from '@/enums'
import { useAppContext, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { RootState } from '@/store'
import diagramColors from '@/styles/diagramColors'

import { handleInputChange } from '../../handlers'
import { NodePropertiesProps } from '../../types'
import { getNodePath } from '../../utils'
import { DiagramPropertiesContent } from './DiagramPropertiesContent'

export const NodeProperties = ({
	diagramRef,
	propertiesWidth,
	nodeId,
}: NodePropertiesProps) => {
	const {
		state: { editMode: isEditMode },
	} = useDetailTabContext()

	const { t } = useAppContext()

	const selectedNode = useAppSelector(
		(state) =>
			state.diagram.diagrams[nodeId]?.form.graph.inspector.inspectedData,
	)

	const thisNode = useAppSelector((state) =>
		state.diagram.diagrams[nodeId].form.graph.nodeDataArray.find(
			(node) => node.key === selectedNode?.key,
		),
	)

	const [nodeLocalProps, setNodeLocalProps] = useImmer({
		nodeHeaderColor: thisNode?.nodeHeaderColor,
		nodeBodyColor: thisNode?.nodeBodyColor,
	})

	const getAllNodesFromTree = useAppSelector(
		(state: RootState) => state?.node?.nodes,
	)

	useEffect(() => {
		setNodeLocalProps({
			nodeHeaderColor: thisNode?.nodeHeaderColor,
			nodeBodyColor: thisNode?.nodeBodyColor,
		})
	}, [
		selectedNode,
		setNodeLocalProps,
		thisNode?.nodeBodyColor,
		thisNode?.nodeHeaderColor,
	])

	const contents = useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_ID'),
				contentValue: selectedNode?.key,
				type: PropertyType.Info,
				isGrouped: true,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_NAME'),
				contentValue: selectedNode?.text,
				type: PropertyType.Info,
				isGrouped: true,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_TYPE'),
				contentValue: selectedNode?.category,
				type: PropertyType.Info,
				isGrouped: true,
			},
			{
				id: 4,
				label: t('DIAGRAM_NODE_FULL_PATH'),
				contentValue:
					getNodePath(getAllNodesFromTree, selectedNode?.key) ||
					t('DIAGRAM_NODE_DELETED'),
				type: PropertyType.Info,
			},
			{
				id: 5,
				label: t('DIAGRAM_NODE_HEADER_COLOR'),
				contentValue: (
					<input
						type="color"
						value={nodeLocalProps?.nodeHeaderColor || diagramColors.node.header}
						onChange={(e) =>
							handleInputChange({
								diagramRef,
								inputType: 'SPECIFIC_NODE_HEADER_COLOR',
								newValue: {
									nodeHeaderColor: e.target.value,
								},
								setLocalProps: setNodeLocalProps,
								selectedElKey: selectedNode?.key,
							})
						}
					/>
				),
				type: PropertyType.Table,
			},
			{
				id: 6,
				label: t('DIAGRAM_NODE_BODY_COLOR'),
				contentValue: (
					<input
						type="color"
						value={nodeLocalProps?.nodeBodyColor || diagramColors.node.body}
						onChange={(e) =>
							handleInputChange({
								diagramRef,
								inputType: 'SPECIFIC_NODE_BODY_COLOR',
								newValue: {
									nodeBodyColor: e.target.value,
								},
								setLocalProps: setNodeLocalProps,
								selectedElKey: selectedNode?.key,
							})
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Table,
			},
		],
		[
			t,
			selectedNode?.key,
			selectedNode?.text,
			selectedNode?.category,
			getAllNodesFromTree,
			nodeLocalProps?.nodeHeaderColor,
			nodeLocalProps?.nodeBodyColor,
			isEditMode,
			diagramRef,
			setNodeLocalProps,
		],
	)

	return (
		<DiagramPropertiesContent
			contents={contents}
			propertiesWidth={propertiesWidth}
		/>
	)
}
