import go, { Diagram } from 'gojs'

import { StructureDto } from '@/endpoints/models'
import { DiagramTemplateType } from '@/enums'
import { useAppSelector } from '@/hooks'
import { TREE_PANEL_DRAG_DROP_NODE_ID } from '@/pages/User/pages/Home/components/TreePanel/drag'
import { useGetDeepDiagramQuery } from '@/rtkQueries'
import diagramColors from '@/styles/diagramColors'
import { LinkData } from '@/types'

import { useDiagramContext } from '../context'
import { addDiagramNode } from '../handlers'
import { getNodeData } from '../Templates/NodeTemplates/api'
import { getModelAsObject } from '../utils'

export const useReactDiagramDrop = () => {
	const { diagramRef, node } = useDiagramContext()
	const allTreeNodes = useAppSelector((state) => state?.node?.nodes)
	const { data: deepDiagram } = useGetDeepDiagramQuery(
		node?.parentStructureId as number,
	)

	const handleDrop = async (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		const droppedNodeId = Number(
			e.dataTransfer.getData(TREE_PANEL_DRAG_DROP_NODE_ID),
		)

		const nodeData = await getNodeData(droppedNodeId)
		const parsedNodeData = JSON.parse(nodeData?.data?.data)
		const columns = parsedNodeData?.columns || []

		const droppedNode = allTreeNodes[droppedNodeId] as StructureDto

		const instanceDiagram = diagramRef?.current?.getDiagram()
		const model = instanceDiagram?.model as go.GraphLinksModel
		const parsedModel = getModelAsObject(instanceDiagram as Diagram)

		if (!model || !instanceDiagram || !instanceDiagram.div) return

		const diagramRect = instanceDiagram.div.getBoundingClientRect()
		const offsetX = e.clientX - diagramRect.left
		const offsetY = e.clientY - diagramRect.top

		// Convert the client coordinates to diagram coordinates
		const docPoint = instanceDiagram.transformViewToDoc(
			new go.Point(offsetX, offsetY),
		)

		const loc = `${docPoint?.x} ${docPoint?.y}`

		const nodePayload = {
			key: droppedNode?.id,
			category: DiagramTemplateType.Table,
			text: droppedNode.name,
			code: droppedNode.code,
			loc,
			columns,
			tableColumnsVisibility: true,
			nodeBodyColor: diagramColors.node.body,
			nodeHeaderColor: diagramColors.node.header,
		}

		const linksPayload = deepDiagram?.links?.map((deepLink, index: number) => {
			const matchedDiagramFormLink = parsedModel?.linkDataArray.find(
				(diagramFormLink: any) =>
					diagramFormLink?.text === deepLink.constraintName,
			)
			return {
				key: index,
				from: deepLink.foreignKeyTableId,
				to: deepLink.primaryKeyTableId,
				text: deepLink.constraintCode,
				linksColor: matchedDiagramFormLink?.linksColor,
				hasName: matchedDiagramFormLink?.hasName,
			}
		}) as LinkData[]

		addDiagramNode({
			instanceDiagram,
			linksPayload,
			model,
			nodePayload,
			parsedModel,
		})
	}

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
	}

	return {
		handleDrop,
		handleDragOver,
		handleDragLeave,
	}
}
