import axios, { AxiosRequestHeaders, AxiosResponse } from 'axios'
import { appConfig } from '@/config'
import { store } from '@/store'
import { HttpMethod } from '@/endpoints/utils'

interface ApiRequestParams {
	url: string
	method: HttpMethod
	data?: any
	params?: any
}

// Create Axios instance
export const axiosInstance = axios.create({
	baseURL: appConfig.apiUrl,
	headers: {
		'Content-Type': 'application/json; charset=utf-8',
		'X-TenantId': appConfig.realm,
	},
})

// Add an interceptor for requests to dynamically add the token
axiosInstance.interceptors.request.use(
	(config) => {
		// Get token from Redux state
		const token = store.getState().auth?.token

		const tokenFromStorage = localStorage.getItem('AUTH_TOKEN')
		const parsedToken = JSON.parse(tokenFromStorage as string).token

		if (parsedToken) {
			config.headers = {
				...config.headers,
				Authorization: `Bearer ${parsedToken}`,
			} as AxiosRequestHeaders
		}

		return config
	},
	(error) => {
		// Handle request error
		return Promise.reject(error)
	},
)

export const apiRequest = async <T, D = any>({
	url,
	method,
	data,
	params,
}: ApiRequestParams): Promise<AxiosResponse<T, D>> => {
	const stringifiedData = JSON.stringify(data)
	try {
		const response = await axiosInstance.request({
			url,
			method,
			data: stringifiedData,
			params,
		})

		return response
	} catch (error) {
		console.error('API request error:', error)
		throw error // Optional: rethrow the error to handle it upstream
	}
}
