import go, { ObjectData } from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

import { AddDiagramNodeProps } from '@/components/Diagram'
import { GoJsNodeTempProps, LinkData } from '@/types'

import { updateDiagramProperty } from './gojsHandlers'

export const updateSpecificNodeHeaderColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	nodeKey: number | undefined,
	newValue: GoJsNodeTempProps,
) => {
	updateDiagramProperty(diagramRef, 'update node color', (model) => {
		const nodeData = model.findNodeDataForKey(nodeKey)

		if (!nodeData) {
			return
		}

		return model.setDataProperty(
			nodeData,
			'nodeHeaderColor',
			newValue.nodeHeaderColor,
		)
	})
}

export const updateSpecificNodeBodyColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	nodeKey: number | undefined,
	newValue: GoJsNodeTempProps,
) => {
	updateDiagramProperty(diagramRef, 'update node color', (model) => {
		const nodeData = model.findNodeDataForKey(nodeKey)

		if (!nodeData) {
			return
		}

		return model.setDataProperty(
			nodeData,
			'nodeBodyColor',
			newValue.nodeBodyColor,
		)
	})
}

export const addDiagramNode = ({
	instanceDiagram,
	linksPayload,
	model,
	nodePayload,
	parsedModel,
}: AddDiagramNodeProps) => {
	// Check if the node already exists
	const isNodeDuplicate = parsedModel?.nodeDataArray.find(
		(node) => node.key === nodePayload.key,
	)

	// Only add the node if it's not a duplicate
	if (isNodeDuplicate) return
	if (!model) return

	instanceDiagram?.startTransaction('Add Node')
	model.addNodeData(nodePayload)
	instanceDiagram?.commitTransaction('Add Node')

	// Add links if they are not duplicates
	const existingLinks = parsedModel?.linkDataArray || []
	const uniqueLinks = linksPayload?.filter(
		(link) =>
			!existingLinks.some((existingLink) => existingLink.key === link.key),
	) as LinkData[]

	// Only add links if there are unique ones to add
	if (uniqueLinks?.length > 0) {
		instanceDiagram?.startTransaction('Add Links')
		model.addLinkDataCollection(uniqueLinks)
		instanceDiagram?.commitTransaction('Add Links')
	}
}

export const removeDiagramNode = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	removedNodeKey: number,
) => {
	const diagram = diagramRef?.current?.getDiagram()
	const graphLinkModel = diagram?.model as go.GraphLinksModel

	const nodeToRemove = diagram?.model.findNodeDataForKey(removedNodeKey)

	const linkToRemove = graphLinkModel.linkDataArray.find(
		(link) => link.from === removedNodeKey || link.to === removedNodeKey,
	)

	if (nodeToRemove && diagram) {
		diagram.startTransaction('remove node')
		diagram.model.removeNodeData(nodeToRemove)
		diagram.commitTransaction('remove node')

		diagram.startTransaction('remove related links')
		graphLinkModel.removeLinkData(linkToRemove as ObjectData)
		diagram.commitTransaction('remove related links')
	} else {
		console.error('Node or Node data is not available:', nodeToRemove)
	}
}
