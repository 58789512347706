import go from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { Dispatch, RefObject } from 'react'

import { TranslationHelper } from '@/context/Locale'
import { openOrCloseAddModal } from '@/store'
import { deleteNodeThunk } from '@/store/slices/diagram/thunks'

import { removeDiagramNode } from '../../handlers'

const $ = go.GraphObject.make

export const createTableNodeContextMenu = (
	dispatch: Dispatch<any>,
	diagramRef: RefObject<ReactDiagram> | undefined,
) => {
	return new go.Adornment('Vertical')
		.add(
			$('ContextMenuButton', $(go.TextBlock, 'Remove table'), {
				click: (_e, obj) => {
					if (!diagramRef) return
					const selectedNode = obj.part

					return removeDiagramNode(diagramRef, Number(selectedNode?.key))
				},
			}),
		)
		.add(
			$('ContextMenuButton', $(go.TextBlock, 'Delete table'), {
				click: (_e, obj) => {
					const node = obj.part

					if (node) {
						dispatch(deleteNodeThunk(node.data.key))
					} else {
						console.error('Node or Node data is not available:', node)
					}
				},
			}),
		)
}

export const createDiagramMenu = (
	goJsDiagram: go.Diagram,
	t: TranslationHelper,
	dispatch: Dispatch<any>,
) => {
	const $ = go.GraphObject.make

	goJsDiagram.contextMenu = $(
		'ContextMenu',
		$(
			'ContextMenuButton',
			{
				click: () => {
					dispatch(openOrCloseAddModal(true))
				},
			},
			$(go.TextBlock, t('DIAGRAM_ADD_TABLE')),
		),
	)
}
