import { apiRequest } from '@/endpoints/axiosInstance'
import { HttpMethod } from '@/endpoints/utils'

export const getNodeData = async <T>(nodeId: number) => {
	try {
		const response = await apiRequest<T>({
			url: `/structure/${nodeId}`,
			method: HttpMethod.GET,
		})

		return response
	} catch (error) {
		console.error(error)
	}
}
