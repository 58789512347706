import { useAppContext, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

import { handleInputChange } from '../../handlers'
import { useLocalDiagramProperties, usePropertyContents } from '../../hooks'
import { DiagramPropertiesProps } from '../../types'
import { DiagramPropertiesContent } from './DiagramPropertiesContent'
import { RoutingTypeSelector } from './RoutingTypeSelector'

export const DiagramProperties = ({
	diagramRef,
	nodeId,
}: DiagramPropertiesProps) => {
	const { t } = useAppContext()
	const {
		state: { editMode: isEditMode },
	} = useDetailTabContext()

	const graphData =
		useAppSelector((state) => state.diagram.diagrams[nodeId]?.form.graph) || {}

	const { localProperties, setLocalProperties } = useLocalDiagramProperties(
		graphData.modelData,
	)

	const anyLabelVisible = graphData.linkDataArray.some((link) => link.hasName)

	const contents = usePropertyContents({
		localProperties,
		anyLabelVisible,
		t,
		isEditMode,
		setLocalProperties,
	})

	return (
		<>
			<DiagramPropertiesContent contents={contents} propertiesWidth={350} />
			<RoutingTypeSelector
				routingType={localProperties.links?.routingType}
				onChange={(e) =>
					handleInputChange({
						diagramRef,
						inputType: 'ROUTING_TYPE_INPUT',
						newValue: {
							links: { routingType: e.target.value },
						},
						setLocalProps: setLocalProperties,
					})
				}
				t={t}
			/>
		</>
	)
}
