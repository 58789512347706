import { LuPanelRightClose, LuPanelRightOpen } from 'react-icons/lu'
import { PiFilePng, PiFileSvg } from 'react-icons/pi'

import { Button } from '@/components'

import { useDiagramContext } from '../context'

interface DiagramControlsProps {
	isPanelOpen: boolean
	togglePanel: () => void
}

export const DiagramControls = ({
	isPanelOpen,
	togglePanel,
}: DiagramControlsProps) => {
	const { isEditMode, exportSvg, exportPng } = useDiagramContext()

	return (
		<div className="flex flex-wrap items-center justify-between h-[50px] px-2.5 border-b-gray-300 border-b border-solid">
			<section className="flex items-center gap-2">
				<Button
					size="sm"
					schema="default"
					onClick={exportPng}
					disabled={!isEditMode}
				>
					<PiFilePng size={25} />
				</Button>
				<Button
					size="sm"
					schema="default"
					onClick={exportSvg}
					disabled={!isEditMode}
				>
					<PiFileSvg size={25} />
				</Button>
			</section>
			<section className="flex items-center gap-2">
				<Button size="sm" schema="default" onClick={togglePanel}>
					{isPanelOpen ? (
						<LuPanelRightClose size={25} />
					) : (
						<LuPanelRightOpen size={25} />
					)}
				</Button>
			</section>
		</div>
	)
}
