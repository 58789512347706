import { ReactNode, useMemo, useState } from 'react'

import { DiagramContext } from '@/components/Diagram/context/DiagramContext'
import { StructureDto } from '@/endpoints/models'
import { DiagramTab } from '@/enums'

import { useReactDiagram } from '../hooks'

interface DiagramProviderProps {
	children: ReactNode
	isEditMode: boolean
	node: StructureDto
}

// Provider component
export const DiagramProvider = ({
	children,
	isEditMode,
	node,
}: DiagramProviderProps) => {
	const [selectedTab, setSelectedTab] = useState(DiagramTab.Diagram)
	const { diagramRef, makeAutoLayout, exportSvg, exportPng } = useReactDiagram({
		isEditMode,
	})

	const value = useMemo(
		() => ({
			diagramRef,
			isEditMode,
			node,
			makeAutoLayout,
			exportSvg,
			exportPng,
			selectedTab,
			setSelectedTab,
		}),
		[
			diagramRef,
			exportPng,
			exportSvg,
			isEditMode,
			makeAutoLayout,
			node,
			selectedTab,
		],
	)

	return (
		<DiagramContext.Provider value={value}>{children}</DiagramContext.Provider>
	)
}
