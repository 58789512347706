import { StructureDetailDto, StructureDto } from '@/endpoints/models'
import { DiagramData } from '@/endpoints/schemas/diagram'
import { CustomAttributeForm } from '@/hooks'
import { DiagramPropertiesI, GenerateCodeEnum, LinkData } from '@/types'
import { NewDiagramProps } from '@/types/diagrams'

import {
	ADD_NEW_DIAGRAM,
	ADD_NEW_DIAGRAM_NODE,
	DIAGRAM_LINK_PROP_UPDATE,
	DIAGRAM_NODE_PROP_UPDATE,
	DIAGRAM_PROP_UPDATE,
	DIAGRAM_UPDATE,
	FOLDER_INIT,
	FOLDER_SAVE,
	FOLDER_UPDATE,
	REMOVE_DIAGRAM,
	REMOVE_DIAGRAM_NODE,
} from './constants'

export type FolderInit = {
	editMode: boolean
	force: boolean
	node: StructureDetailDto
	type: typeof FOLDER_INIT
}

type FolderUpdate = {
	node: StructureDto
	payload: FolderData
	type: typeof FOLDER_UPDATE
}
type AddNewDiagram = {
	nodeId: number
	payload: any
	type: typeof ADD_NEW_DIAGRAM
}

type AddNewDiagramNode = {
	nodeId: number
	payload: any
	type: typeof ADD_NEW_DIAGRAM_NODE
}

type RemoveDiagramNode = {
	nodeId: number
	removedNodeId: number
	selectedTabDetailId: number
	type: typeof REMOVE_DIAGRAM_NODE
}

type RemoveDiagram = {
	node: StructureDto
	type: typeof REMOVE_DIAGRAM
	update: any
}

export type FolderSave = {
	metadata: {
		nodeId: number
	}
	payload: void
	type: typeof FOLDER_SAVE
}

export interface DiagramUpdatePayload {
	diagramSvg: string
	modifiedDiagramModel: any
	panelProperties: DiagramPropertiesI
	selectedTabDetailId: number
}

export interface LinkPropUpdatePayload {
	data: LinkData
	nodeId: number
	selectedTabDetailId: number
}

export interface UpdateDiagram {
	node: StructureDto
	payload: DiagramUpdatePayload
	type: typeof DIAGRAM_UPDATE
}

interface DiagramPropUpdate {
	node: StructureDto
	payload: DiagramUpdatePayload
	type: typeof DIAGRAM_PROP_UPDATE
}

interface DiagramNodePropUpdate {
	node: StructureDto
	payload: DiagramUpdatePayload
	type: typeof DIAGRAM_NODE_PROP_UPDATE
}

interface DiagramLinkPropUpdate {
	node: StructureDto
	payload: LinkPropUpdatePayload
	type: typeof DIAGRAM_LINK_PROP_UPDATE
}

export interface OpenedFolderData {
	dirty: boolean
	form: FolderDataForm
	parsedEditMode: boolean
	tab: OpenedFolderTab
}

export type FolderDataForm = Omit<Partial<FolderData>, 'namingConfig'> & {
	generateCode?: GenerateCodeEnum
	generateTableColumnCode?: GenerateCodeEnum
}

export interface FolderData {
	customAttributes?: CustomAttributeForm[]
	diagram: DiagramData
	diagramSvg?: string
	diagrams: NewDiagramProps[]
	name: string
	namingConfig?: {
		generateCode?: GenerateCodeEnum
		generateTableColumnCode?: GenerateCodeEnum
	}
	ownerId?: number
	ownerName?: string
	stereotypeCode?: string
	stereotypeId?: number
}

export enum OpenedFolderTab {
	Dependencies = 'Dependencies',
	Diagram = 'Diagram',
	Diagrams = 'Diagrams',
	Overview = 'Overview',
	Validation = 'Validation',
}

export type Action =
	| FolderInit
	| FolderUpdate
	| FolderSave
	| AddNewDiagram
	| RemoveDiagram
	| UpdateDiagram
	| DiagramPropUpdate
	| DiagramNodePropUpdate
	| DiagramLinkPropUpdate
	| AddNewDiagramNode
	| RemoveDiagramNode
