import debounce from 'debounce'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { FormWrap } from '@/components'
import {
	SelectFormField,
	TextAreaFormField,
	TextFormField,
} from '@/components/UberForm'
import { useAppContext, useAppDispatch, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { DiagramOverviewProps } from '@/pages/User/pages/Home/pages/DiagramDetail/types'
import { HeaderContainer } from '@/pages/User/pages/Home/pages/SystemDetail/pages/DeploymentPackages/styles'
import { diagramUpdated } from '@/store'
import { saveDiagram } from '@/store/slices/thunks'
import { MainContentWrap } from '@/styles'

import { useResponsibleUsers, useStructureStatus } from '../../../utils'

export const DiagramOverview = () => {
	const { t } = useAppContext()
	const {
		state: { node, editMode },
	} = useDetailTabContext()

	const dispatch = useAppDispatch()

	const form =
		useAppSelector((state) => state.diagram.diagrams[node.id]?.form) || {}

	const nodeStates = useStructureStatus(t)
	const userList = useResponsibleUsers(form)

	const { watch, control } = useForm<DiagramOverviewProps>({
		defaultValues: {
			name: form.name,
			comment: form.comment,
			description: form.description,
			status: form.status,
			responsiblePersonId: form.responsiblePersonId,
			responsibleAnalystId: form.responsibleAnalystId,
		},
		values: form,
	})

	const saveDebounced = debounce((formValues) => {
		dispatch(diagramUpdated({ node, update: formValues }))

		dispatch(saveDiagram(node))
	}, 500)

	useEffect(() => {
		const { unsubscribe } = watch((formValues) => {
			saveDebounced(formValues)
		})
		return () => unsubscribe()
	}, [watch])

	return (
		<MainContentWrap>
			<FormWrap>
				<form>
					<section className="py-4">
						<HeaderContainer>
							<h1>Overview</h1>
						</HeaderContainer>
						<div className="grid grid-cols-2 gap-4">
							<Controller
								name="name"
								control={control}
								render={({ field }) => (
									<TextFormField
										title="Name"
										disabled={!editMode}
										initialValue={field.value}
										{...field}
									/>
								)}
							/>

							<Controller
								name="comment"
								control={control}
								render={({ field }) => (
									<TextFormField
										title="Comment"
										disabled={!editMode}
										initialValue={field.value}
										{...field}
									/>
								)}
							/>
						</div>
						<div className="grid gap-4">
							<Controller
								name="description"
								control={control}
								render={({ field }) => (
									<TextAreaFormField
										disabled={!editMode}
										title={t('TABLE_GENERAL_DESCRIPTION')}
										rows={5}
										initialValue={field.value}
										{...field}
									/>
								)}
							/>
						</div>
					</section>

					<section className="py-4">
						<HeaderContainer>
							<h1>Project Metadata</h1>
						</HeaderContainer>
						<div className="grid grid-cols-2 gap-4">
							<Controller
								name="status"
								control={control}
								render={({ field }) => (
									<SelectFormField
										disabled={!editMode}
										title={t('TABLE_GENERAL_TABLE_STATUS')}
										initialValue={field.value}
										options={nodeStates}
										valueKey="value"
										labelKey="label"
										allowEmpty
										{...field}
									/>
								)}
							/>
							<Controller
								name="responsiblePersonId"
								control={control}
								render={({ field }) => (
									<SelectFormField
										disabled={!editMode}
										title={t('RESPONSIBLE_PERSON')}
										options={userList.data || []}
										valueKey="id"
										fieldIsLoading={userList.reloading}
										labelKey="value"
										initialValue={field.value}
										isNumeric
										allowEmpty
										{...field}
									/>
								)}
							/>
							<Controller
								name="responsibleAnalystId"
								control={control}
								render={({ field }) => (
									<SelectFormField
										disabled={!editMode}
										title={t('RESPONSIBLE_ANALYST')}
										options={userList.data || []}
										fieldIsLoading={userList.reloading}
										valueKey="id"
										labelKey="value"
										initialValue={field.value}
										isNumeric
										allowEmpty
										{...field}
									/>
								)}
							/>
						</div>
					</section>
				</form>
			</FormWrap>
		</MainContentWrap>
	)
}
