export default {
	ACRONYM: 'Acronym',
	ACRONYMS: 'Acronyms',
	ACRONYM_WORD: 'Word',
	ACTIONS: 'Actions',
	ACTIVE: 'Active',
	ADD: 'Add',
	ADD_ELEMENT: 'Drop element to add',
	ADD_ELEMENTS_TO_DEPLOYMENT_PKG: 'Add elements to deployment package',
	ADD_ELEMENT_TO_DEPLOYMENT_PKG: 'Add element to deployment package',
	ADD_ENVIRONMENT: 'Add environment',
	ADD_ENVIRONMENTS: 'Add environments',
	ADD_FOLDER: 'Add folder',
	ADD_NEW: 'Add new',
	ADD_NEW_ACRONYM: 'Add new acronym',
	ADD_NEW_API_COLLECTION: 'Add new rowset',
	ADD_NEW_API_NODE: 'Add new api node',
	ADD_NEW_DIAGRAM: 'Add new diagram',
	ADD_NEW_FOLDER: 'Add new folder',
	ADD_NEW_MAPPING: 'Add new mapping',
	ADD_NEW_MAPPING_READ: 'Add new read mapping',
	ADD_NEW_MAPPING_WRITE: 'Add new write mapping',
	ADD_NEW_QUEUE: 'Add new queue',
	ADD_NEW_RELEASE: 'Add new deployment package',
	ADD_NEW_SYSTEM: 'Add new system',
	ADD_NEW_TABLE: 'Add new table',
	ADD_NEW_VIEW: 'Add new view',
	ADD_NEW_WORKFLOW: 'Add new workflow',
	ADD_DIAGRAM: 'Add diagram',
	ADD_OBJECT_SETTINGS: 'Add object',
	ADD_STEREOTYPE: 'Add stereotype',
	ADD_SYSTEM: 'Add system',
	ADD_TABLE: 'Add table',
	ADD_WORKFLOW: 'Add workflow',
	ALLOWED_IGNORE: 'Allowed ignore',
	API_COLLECTION: 'Api collection',
	API_MAPPINGS: 'API Mappings',
	API_NODE: 'Api node',
	API_NODE_COLUMNS_TYPE: 'Type',
	API_NODE_COLUMN_TYPE_IN: 'In',
	API_NODE_COLUMN_TYPE_INTERNAL: 'Internal',
	API_NODE_COLUMN_TYPE_IN_OUT: 'In/Out',
	API_NODE_COLUMN_TYPE_OUT: 'Out',
	APPLICATION_NAME: 'MDD',
	AUTOCOMPLETE_ELEMENTS: 'Autocomplete elements (table names & columns)',
	AUTOCOMPLETE_KEYWORDS: 'Autocomplete keywords',
	AUTOCOMPLETE_SNIPPETS: 'Autocomplete snippets',
	AUTOSAVE_DIRTY: 'Autosaving changes…',
	AUTOSAVE_SAVED: 'Working data saved',
	BACK: 'Back',
	BASIC: 'Basic',
	BATCH_DELETE: 'Batch delete',
	BATCH_EDIT: 'Batch edit',
	BATCH_EXPORT: 'Generate script',
	BUSINESS_DOMAINS: 'Business domains',
	BUSINESS_DOMAIN_DESCRIPTION: 'Description',
	BUSINESS_DOMAIN_NAME: 'Name',
	BUTTON_PERMISSIONS: 'Permissions',
	CANCEL: 'Cancel',
	CANCEL_AND_UNLOCK_ALL_ELEMENTS: 'Cancel and unlock all elements',
	CANCEL_EDIT_HEADER: 'Cancel edit of {0}',
	CANCEL_EDIT_MESSAGE:
		'This will erase your autosaved working version of {0}. Do you want to proceed?',
	CANCEL_SELECT_MODE: 'Cancel select mode',
	CATEGORY: 'Category',
	CHANGE: 'Change',
	CHANGE_PASSWORD: 'Change password',
	CHECK: 'Check',
	CHECKBOX_FALSE: 'No',
	CHECKBOX_TRUE: 'Yes',
	CLICK_TO_OPEN_TABLE: 'Click to open the table',
	CLOSE: 'Close',
	CLOSE_ALL_TABS: 'Close all tabs',
	CLOSE_OTHER_TABS: 'Close other tabs',
	CLOSE_TAB: 'Close tab',
	CODE: 'Code',
	CODE_PREVIEW: 'code preview',
	COLUMN: 'Column',
	COLUMNS: 'Columns',
	COLUMN_WITH_SAME_CODE_ALREADY_EXISTS: 'Column with same code already exists',
	COLUMN_WITH_SAME_NAME_ALREADY_EXISTS: 'Column with same name already exists',
	COMMENT: 'Comment',
	COMPANY: 'Company',
	COMPLETE: 'Complete',
	CONFIRM: 'Confirm',
	CONFIRM_CLOSE_MESSAGE:
		'Closing this modal will discard your changes, do you want to continue?',
	CONSTRAINT_TYPE_CHECK: 'Check',
	CONSTRAINT_TYPE_CUSTOM: 'Custom',
	CONSTRAINT_TYPE_FOREIGN_KEY: 'Foreign key',
	CONSTRAINT_TYPE_PRIMARY_KEY: 'Primary key',
	CONSTRAINT_TYPE_UNIQUE: 'Unique',
	CONTEXT_ADD_API_NODE: 'Add api node',
	CONTEXT_ADD_API_ROWSET: 'Add rowset',
	CONTEXT_ADD_FOLDER: 'Add folder',
	CONTEXT_ADD_MAPPING: 'Add mapping',
	CONTEXT_ADD_MAPPING_READ: 'Add read mapping',
	CONTEXT_ADD_MAPPING_WRITE: 'Add write mapping',
	CONTEXT_ADD_QUEUE: 'Add queue',
	CONTEXT_ADD_TABLE: 'Add table',
	CONTEXT_ADD_VIEW: 'Add view',
	CONTEXT_ADD_WORKFLOW: 'Add workflow',
	CONTEXT_DELETE: 'Delete',
	CONTEXT_LOCK: 'Lock',
	CONTEXT_OPEN_TAB: 'Open in new tab',
	CONTEXT_UNLOCK: 'Unlock',
	COPIED: 'Copied',
	COPY: 'Copy',
	COPYRIGHT: '© 2021 Adastra, Member of Adastra Group',
	CREATE: 'Create',
	CREATE_ALL_DUPLICIT_TABLES_ANYWAY: 'Create all duplicit tables anyway',
	CREATE_ANYWAY: 'Create anyway',
	CREATE_BUSINESS_DOMAIN: 'Create business domain',
	CREATE_DDL: 'Create',
	CREATE_DEPENDENCY: 'Create dependency',
	CREATE_DEPLOYMENT_PACKAGE:
		'No results found, would you like to create {0} as a new DP?',
	CREATE_DOMAIN: 'Create domain',
	CREATE_OBJECT_SETTINGS: 'Create object types',
	CREATE_STEREOTYPE: 'Create stereotype',
	CREATE_SYSTEM: 'Create system',
	CREATE_TECH_COLUMN: 'Create technical column',
	CSV_CAN_BE_IMPORTED_ONLY_IN_FOLDER: 'CSV can be imported only in folder',
	CURRENT_NODE_NAME_ACRONYM: 'Current node name acronym',
	CUSTOM_ATTRIBUTE: 'Custom attribute',
	CUSTOM_ATTRIBUTES: 'Custom attributes',
	CUSTOM_ATTRIBUTE_NEW: 'Create custom attribute',
	CUSTOM_ATTRIBUTE_UPDATE: 'Update custom attribute',
	CUSTOM_LISTS_OF_VALUES: 'Custom lists of values',
	CUSTOM_LOV: 'Custom LOV',
	CUSTOM_LOV_NEW: 'Create custom LOV',
	CUSTOM_LOV_UPDATE: 'Update custom LOV',
	DATABASE_CONNECTION: 'Database connection',
	DATE_OF_DEPLOYMENT: 'Date of deployment',
	DAYS: 'Days',
	DDL_CREATION_FAILED: 'DDL generation failed',
	DDL_SCRIPT: 'DDL script',
	DEFAULT: 'Default',
	DEFAULT_MAPPING: 'Default mapping',
	DEFAULT_MAPPING_HISTORICAL: 'Historical mapping',
	DEFAULT_MAPPING_INTERFACE: 'Interface mapping',
	DEFAULT_MAPPING_REFERENCE: 'Reference mapping',
	DELETE: 'Delete',
	DELETE_CUSTOM_ATTRIBUTE: 'Delete custom attribute',
	DELETE_CUSTOM_LOV: 'Delete custom LOV',
	DELETE_FOLDER: 'Delete folder',
	DELETE_MAPPING: 'Delete mapping',
	DELETE_MAPPING_READ: 'Delete read mapping',
	DELETE_MAPPING_WRITE: 'Delete write mapping',
	DELETE_NODE_HEADER_NOT_EMPTY: 'Delete not empty node',
	DELETE_NODE_MESSAGE: 'Do you want to delete "{0}"?',
	DELETE_NODE_MESSAGE_NOT_EMPTY:
		'Entity {0} has some children. Are you really want to delete {0} and all his children?',
	DELETE_SELECTED_ELEMENTS: 'Delete selected elements and their children',
	DELETE_SYSTEM: 'Delete system',
	DELETE_TABLE: 'Delete table',
	DELETE_VIEW: 'Delete view',
	DELETE_WORKFLOW: 'Delete workflow',
	DEPENDENCIES: 'Dependencies',
	DEPLOYED_BY: 'Deployed by',
	DEPLOYED_HISTORY_ID: 'Deployed History Id',
	DEPLOYED_HISTORY_ID2: 'Deployed History Id',
	DEPLOYED_PUSHED_VERSION: 'Deployed pushed version',
	DEPLOYED_UNPUSHED_CHANGES_WARN:
		'Unpushed changes will only be reflected in the preview',
	DEPLOYED_UNPUSHED_CHANGES_WARN_TITLE: 'Unpushed changes',
	DEPLOYED_VERSION: 'Deployed version',
	DEPLOYED_VERSION_INFO: 'Version {0}',
	DEPLOYMENT: 'Deployment',
	DEPLOYMENT_GIT: 'Deployment to git repository',
	DEPLOYMENT_GIT_PUSHED: 'Successfully pushed to git repository',
	DEPLOYMENT_MARK_CREATED: 'Structure was successfully marked as deployed',
	DESCRIPTION: 'Description',
	DIAGRAM_ADD_TABLE: 'Add table',
	DIAGRAM_AUTO_LAYOUT: 'Auto layout',
	DIAGRAM_COLUMNS_CODE: 'Table columns code',
	DIAGRAM_COLUMNS_NAME: 'Table columns name',
	DIAGRAM_CHANGELOG: 'Diagram elements have changed',
	DIAGRAM_DELETE_TABLE: 'Delete table',
	DIAGRAM_GRID_CELL_SIZE: 'Grid cell size',
	DIAGRAM_GRID_HORIZONTAL_LINE: 'Grid horizontal line',
	DIAGRAM_GRID_INTERVAL_HORIZONTAL_LINE: 'Grid interval horizontal line',
	DIAGRAM_GRID_INTERVAL_VERTICAL_LINE: 'Grid interval vertical line',
	DIAGRAM_GRID_SWITCH: 'Grid',
	DIAGRAM_GRID_VERTICAL_LINE: 'Grid vertical line',
	DIAGRAM_LINK_DOESNT_EXIST_IN_PROJECT: 'Link does not exist in project',
	DIAGRAM_LINK_COLOR: 'Link color',
	DIAGRAM_LINK_PROPERTIES: 'Constraint properties',
	DIAGRAM_LINK_PROPERTIES_TEXT: 'Please select a link to view properties',
	DIAGRAM_MANUAL_LAYOUT: 'Manual layout',
	DIAGRAM_NODE_BODY_COLOR: 'Node body color',
	DIAGRAM_NODE_COLUMNS_BACKGROUND: 'Table columns background',
	DIAGRAM_NODES_COMMENTS: 'Show comments',
	DIAGRAM_NODE_CONSTRAINT_COLOR: 'Constraint color',
	DIAGRAM_NODE_DELETED: 'Deleted node',
	DIAGRAM_NODES_DESCRIPTION: 'Show desriptions',
	DIAGRAM_NODE_ID: 'ID:',
	DIAGRAM_NODE_FROM: 'From node:',
	DIAGRAM_NODE_FULL_PATH: 'Full path:',
	DIAGRAM_NODE_HEADER_COLOR: 'Table header color',
	DIAGRAM_NODE_NAME: 'Name:',
	DIAGRAM_NODE_NAME_BACKGROUND: 'Node name background',
	DIAGRAM_NODE_PROPERTIES: 'Table properties',
	DIAGRAM_NODE_PROPERTIES_TEXT: 'Please select a table to view properties',
	DIAGRAM_NODE_TEXT: 'Text:',
	DIAGRAM_NODE_TO: 'To node:',
	DIAGRAM_NODE_TYPE: 'Type:',
	DIAGRAM_PK_CODE: 'Primary key code',
	DIAGRAM_PK_NAME: 'Primary key name',
	DIAGRAM_PROPERTIES: 'Diagram properties',
	DisplayOptions: 'DISPLAY OPTIONS',
	DIAGRAM_PROPERTIES_NONE: 'None at this time.',
	DIAGRAM_REMOVE_TABLE: 'Remove table',
	DIAGRAM_ROUTING_TYPE: 'Routing type:',
	DIAGRAM_ROUTING_TYPE_DIRECT: 'Direct',
	DIAGRAM_ROUTING_TYPE_ORTHOGONAL: 'Orthogonal',
	DIAGRAM_TABLE_NAME: 'Table name',
	DIAGRAM_TABLE_SHOW_COLUMNS: 'Show columns',
	DIAGRAM_TABLE_CONSTRAINT_NAME: 'Show constraint name',
	DIAGRAM_TABLE_CODE: 'Table code',
	DIAGRAM_VALIDATION_TEXT_MISMATCH: 'Was renamed.',
	DIAGRAM_VALIDATION_LINK_NOT_EXIST: 'Link does not exist',
	DIAGRAM_VALIDATION_TABLE_NOT_EXIST: 'Table does not exist in the project',
	DIAGRAM_DELETED_NODES_WARNING:
		'The following nodes have been deleted, remove them and push the changes to finalize the deletion:',
	DIAGRAM_WARNING:
		'Diagram ref is null or undefined. This occurred during the validateTablesInDiagram process. Ensure that the diagram reference is correctly set before this process. Failure to define the diagram reference may result in accuracy errors during validation.',
	DOMAINS: 'Domains',
	DOMAIN_CODE: 'Code',
	DOMAIN_COMPANY_NAME: 'Company name',
	DOMAIN_CUSTOM: 'Custom',
	DOMAIN_DATA_TYPE: 'Data type',
	DOMAIN_DEFAULT_VALUE: 'Default value',
	DOMAIN_DESCRIPTION: 'Description',
	DOMAIN_HAS_TEMPLATE: 'Has template',
	DOMAIN_MANDATORY: 'Mandatory', // only for import
	DOMAIN_NAME: 'Domain name',
	DOMAIN_NOT_FOUND: 'Domain not found',
	DOMAIN_NOT_NULL: 'Not null',
	DOMAIN_TECHNOLOGY_CODE: 'Technology code',
	DOMAIN_TEMPLATE: 'Template',
	DOMAIN_TEMPLATE_VALUE_FORMAT: 'Template value format',
	DOMAIN_TEMPLATE_VALUE_REGEX: 'Template value regex',
	DOMAIN_TEXT_VALUE_FLAG: 'Text value',
	DOMAIN_UPDATE_AFFECTED_MESSAGE:
		'This change affects {0} columns in {1} tables.',
	DOMAIN_UPDATE_CONFIRM_TITLE: 'Update columns?',
	DONE: 'Done',
	DOWNLOAD: 'Download',
	DOWNLOAD_IMPACT_AS_CSV: 'Download impact as CSV',
	DOWNLOAD_VERSION_TO_COMPARE: 'Download (version to compare)',
	DOWNLOAD_ZIP: 'Download zip',
	DO_NOT_IMPORT_TECHNICAL_COLUMNS: 'Do not import technical columns',
	DRAG_AND_DROP_COLUMNS_TO_MATCH_FIELDS:
		'Drag & Drop columns to match the fields',
	DRAG_COLUMN_HERE: 'Drag column here',
	DROP_FILE: 'Drop file here',
	DROP_TABLE: 'Drop table',
	DUPLICATE: 'Duplicate',
	DUPLICATE_COLUMN_CODE: 'Duplicate column code',
	DUPLICATE_COLUMN_NAME: 'Duplicate column name',
	DUPLICIT_TABLES: 'Duplicit tables',
	EDIT: 'Edit',
	EDIT_AND_LOCK: 'Edit and lock',
	EDITABLE_BY_ACTUAL_USER: 'Editable by actual user',
	ELEMENT: 'Element',
	ELEMENTS: 'Elements',
	ELEMENTS_IN_RELEASE: 'Elements',
	ELEMENT_TYPE: 'Element type',
	ELEMENT_TYPES: 'Element types',
	EMAIL: 'Email',
	ENTER_SELECT_MODE: 'Enter select mode',
	ENTITY_TYPE_AGGREGATE: 'Aggregate',
	ENTITY_TYPE_AUXILIARY: 'Auxiliary',
	ENTITY_TYPE_COMPOSITION: 'Composition',
	ENTITY_TYPE_FUNDAMENTAL: 'Fundamental',
	ENTITY_TYPE_METADATA: 'Metadata',
	ENTITY_TYPE_RELATION: 'Relation',
	ENTITY_TYPE_SNAPSHOT: 'Snapshot',
	ENTITY_TYPE_SPECIALISATION: 'Specialisation',
	ENTITY_TYPE_TRANSACTION: 'Transaction',
	ENVIRONMENT: 'Environment',
	ENVIRONMENTS: 'Environments',
	ENVIRONMENTS_IN_RELEASE: 'Environments',
	ERROR: 'Error',
	ERROR_NO_CRON: 'Cron string not set',
	ERROR_NO_DAY: 'Day not set',
	ERROR_NO_OTHER_WORKFLOW: 'Other workflow not selected',
	ERROR_NO_SCRIPT: 'Script not set',
	ERROR_NO_TIME: 'Time not set',
	ERROR_NO_WEEKDAY: 'Weekday not set',
	ERROR_WHEN_SAVING: 'Error when saving',
	EVENT_NAME: 'Event name',
	EXECUTE_MAPPING_UNSET: '<< UNDEFINED >>',
	EXECUTE_READ_MAPPING: 'Mapping',
	EXECUTE_READ_NODE: 'Execute (Read)',
	EXECUTE_READ_RUN_MAPPING: 'Read {0}',
	EXECUTE_WRITE_CALL_MAPPING: 'Call {0}',
	EXECUTE_WRITE_MAPPING: 'Mapping',
	EXECUTE_WRITE_METHOD: 'Execution method',
	EXECUTE_WRITE_METHOD_CALL_MAPPING: 'Call mapping',
	EXECUTE_WRITE_METHOD_RUN_MAPPING: 'Run mapping',
	EXECUTE_WRITE_METHOD_RUN_SCRIPT: 'Run script',
	EXECUTE_WRITE_NODE: 'Execute (Write)',
	EXECUTE_WRITE_RUN_MAPPING: 'Run {0}',
	EXECUTE_WRITE_RUN_SCRIPT: 'Run script',
	EXECUTE_WRITE_SCRIPT: 'Script',
	EXISTING: 'Existing',
	EXPECTED_FORMAT: 'Expected format',
	EXPLICIT_RIGHTS: 'Explicit rights',
	EXPORT: 'Export',
	FIELDS: 'Fields',
	FIELD_CODE: 'Code',
	FIELD_COMMENT: 'Comment',
	FIELD_CONNECTION: 'Connection String',
	FIELD_DESCRIPTION: 'Description',
	FIELD_DIRECT_DEPLOYMENT: 'Direct deployment',
	FIELD_DOMAIN: 'Domain',
	FIELD_GIT_LOGIN_NAME: 'GIT LoginName',
	FIELD_GIT_PASSWORD_TOKEN: 'GIT Password Token',
	FIELD_GIT_PRIVATE_KEY: 'Private Key',
	FIELD_GIT_PUBLIC_KEY: 'Public Key',
	FIELD_GIT_URL: 'Git URL',
	FIELD_ID: 'Id',
	FIELD_IS_NOT_MATCHED: 'Field is not matched',
	FIELD_NAME: 'Name',
	FIELD_PRODUCTION: 'Production',
	FIELD_REMEMBER_PASSWORD: 'Remember password',
	FIELD_SCRIPT: 'Script',
	FIELD_USERNAME: 'Username',
	FIELD_VALUE: 'Value',
	FILE_EXTENSION: 'File extension',
	FILTER: 'Filter',
	FILTER_ALL_PLACEHOLDER: 'All',
	FILTER_BUTTON: 'Filter',
	FILTER_NO_RESULT_TEXT: 'No results',
	FINISH_NODE: 'Finish node',
	FINISH_SCRIPT: 'Run script',
	FINISH: 'Finish',
	FIX_IMPACTS: 'Fix impacts',
	FOLDER: 'Folder',
	FOLDER_WORKFLOW: 'Folder workflow',
	FRIDAY: 'Friday',
	FROM: 'From',
	FUNCTIONAL_NODES: 'Functional',
	GENERATED_SQL_MODAL_TITLE: 'Generated SQL',
	GENERATE_ELEMENT_CODE: 'Generate element code',
	GENERATE_SQL: 'Generate SQL',
	GENERATE_TABLE_COLUMN_CODE: 'Generate table column code',
	GRAPH_EDITOR_ISSUE_NO_FINISH_NODE: 'No finish node',
	GRAPH_EDITOR_ISSUE_NO_INIT_NODE: 'No init node',
	GRAPH_EDITOR_UNABLE_FIND_TARGET: 'Unable to find target/source port',
	HAS_HEADERS: 'Has headers',
	HH: 'HH',
	HISTORICAL_TABLE: 'Historical table',
	HISTORY_TABLE: 'History table',
	IGNORE: 'Ignore',
	IGNORE_ERRORS: 'Ignore errors',
	IMPACTED_COLUMNS: 'Impacted columns',
	IMPACTED_TABLES: 'Impacted tables',
	IMPACTED_TABLES_AND_COLUMNS: 'Impacted tables and columns',
	IMPACTS: 'Impacts',
	IMPORT: 'Import',
	IMPORT_BUTTON: 'Import',
	IMPORT_CASE_SENSITIVE: 'Case sensitive',
	IMPORT_COLUMNS: 'Import columns',
	IMPORT_COLUMN_COMMENT: 'Column comment',
	IMPORT_COLUMN_NAME: 'Column name',
	IMPORT_COLUMN_TYPE: 'Column data type',
	IMPORT_CREATE_NEW_DOMAINS: 'Create new domains',
	IMPORT_CSV_COLUMNS_ERROR:
		'At least 2 columns (Column name, Data type) are required. {0} columns found.',
	IMPORT_CSV_FORMAT_ERROR:
		"File doesn't seem to be CSV. Only CSV files can be imported.",
	IMPORT_CSV_INFO:
		'CSV file, first row is header, with {0} separator and 3 columns:',
	IMPORT_ERROR_DESCRIPTION: 'Please, upload file with valid format.',
	IMPORT_ERROR_TITLE: 'Wrong file format',
	IMPORT_FAILED: 'Import failed',
	IMPORT_FAILED_UKNOWN_ERROR: 'Failed to import the file.',
	IMPORT_GENERATE_CODES: 'Generate Codes',
	IMPORT_MORE_TEXT: 'And {0} more...',
	IMPORT_NAMES_NORMALISATION: 'Names normalisation',
	IMPORT_NAMES_NORMALISATION_NONE: 'None',
	IMPORT_NAMES_NORMALISATION_UPPERCASE_ALL: 'Uppercase all',
	IMPORT_NAMES_NORMALISATION_UPPERCASE_FIRST_CHAR:
		'Uppercase the first characters',
	IMPORT_STRUCTURE: 'Import structure',
	IMPORT_SYSTEM: 'Import system',
	INCOMPATIBLE_TARGET: 'Incompatible target',
	INCONSISTENT_DOMAINS: 'Inconsistent domains',
	INCONSISTENT_TABLES: 'Inconsistent tables',
	INDEXES_NO_COLUMNS_INFO:
		'You will be able to create indexes as soon as you define at least one table column.',
	INDEX_EDIT_MODAL_TITLE: 'Edit index {0}',
	INDEX_NAME: 'Index name',
	INHERITED_RIGHTS: 'Inherited rights',
	INIT_CRON: 'Cron string',
	INIT_DAY: 'Day',
	INIT_INTERVAL: 'Interval',
	INIT_INTERVAL_DAILY: 'Daily',
	INIT_INTERVAL_MONTHLY: 'Monthly',
	INIT_INTERVAL_WEEKLY: 'Weekly',
	INIT_NODE: 'Init node',
	INIT_OTHER_WORKFLOW: 'Other workflow',
	INIT_SCRIPT: 'Script',
	INIT_TIME: 'Time',
	INIT_TYPE: 'Start type',
	INIT_TYPE_CRON: 'Cron string',
	INIT_TYPE_INTERVAL: 'Interval',
	INIT_TYPE_SCRIPT: 'Run script',
	INIT_TYPE_WORKFLOW_FINISH: 'Other workflow finish',
	INIT_WEEKDAY: 'Weekday',
	INPUT_PARAMETER: 'Input parameter',
	Info: 'INFO',
	INPUT_TYPE: 'Input type',
	ISSUES_TITLE: 'Issues',
	KEYS_NO_COLUMNS_INFO:
		'You will be able to create keys as soon as you define at least one table column.',
	LABEL: 'Label',
	LAST_VERSION: 'Version {0} by {1}',
	LATEST_RELEASE: 'Latest release',
	LEFT_PORT: 'left',
	LIST_OF_RELEASES: 'List of releases',
	LOCK: 'Lock',
	LOCKED_BY_USER: 'Locked by {0}',
	LOCKED_BY_YOU: 'Locked by you',
	LOCK_RESOURCE: 'Lock resource',
	LOGIN: 'Login',
	LOGIN_EXPIRED_TOKEN: 'Your session has expired',
	LOGIN_HARD_LOCK:
		'Your account is banned. Contact admin to unlock your account',
	LOGIN_INVALID_CREDENTIALS: 'Invalid username or password',
	LOGIN_PASSWORD: 'Password',
	LOGIN_SOFT_LOCK: 'Your account is locked for {0}',
	LOGIN_SUBMIT: 'Login',
	LOGIN_USERNAME: 'Username',
	LOGOUT: 'Logout',
	LETTER_CASE_TOGGLE: 'Letter case toggle',
	MAKE_CURRENTLY_DISPLAYED_VERSION_THE_LATEST_ONE:
		'Make currently displayed version the latest one',
	MAPPING: 'Mapping',
	MAPPINGS_NOT_EXISTS: 'Mappings not exists',
	MAPPINGS_READ: 'Read mappings',
	MAPPING_CODE: 'Code',
	MAPPING_COLUMNS_COLUMN: 'Column',
	MAPPING_COLUMNS_COMMENT: 'Comment',
	MAPPING_COLUMNS_DESCRIPTION: 'Description',
	MAPPING_COLUMNS_GROUP_BY_FLAG: 'Group by flag',
	MAPPING_COLUMNS_KEY_FLAG: 'Key flag',
	MAPPING_COLUMNS_MAPPING: 'Mapping',
	MAPPING_COLUMNS_OUTER_MAPPING: 'Outer mapping',
	MAPPING_COLUMNS_UPDATE_FLAG: 'Update flag',
	MAPPING_COMMENT: 'Comment',
	MAPPING_CONDITION: 'Mapping Run Condition',
	MAPPING_CONTEXT_SCRIPT: 'Mapping context script',
	MAPPING_CRITERIA: 'Criteria',
	MAPPING_DEFAULT: 'Mapping default',
	MAPPING_DELETE_CONTEXT_SCRIPT: 'Mapping delete context script',
	MAPPING_DESCRIPTION: 'Description',
	MAPPING_DISTINCT: 'Distinct',
	MAPPING_FIELD_DESCRIPTION: '{0} description',
	MAPPING_NAME: 'Name',
	MAPPING_OUT_WHERE: 'Out where',
	MAPPING_POST_MAPPING_SCRIPT: 'Post-mapping script',
	MAPPING_PRE_MAPPING_SCRIPT: 'Pre-mapping script',
	MAPPING_READ: 'Read mapping',
	MAPPING_ROWSET: 'Rowset',
	MAPPING_SOURCES: 'Sources',
	MAPPING_SOURCES_ALIAS: 'Alias',
	MAPPING_SOURCES_EXPRESSION: 'Expression',
	MAPPING_SOURCES_OBJECT: 'Source Object',
	MAPPING_SOURCES_OBJECT_TYPE: 'Object Type',
	MAPPING_SOURCES_OBJECT_TYPE_DUMMY: 'Dummy',
	MAPPING_SOURCES_OBJECT_TYPE_SUB_SELECT: 'Sub Select',
	MAPPING_SOURCES_OBJECT_TYPE_TABLE: 'Table',
	MAPPING_SOURCES_OBJECT_TYPE_VIEW: 'View',
	MAPPING_SOURCES_SOURCE_CONTEXT: 'Source Context',
	MAPPING_SOURCES_STEREOTYPE: 'Stereotype',
	MAPPING_SOURCES_STEREOTYPE_FROM: 'FROM',
	MAPPING_SOURCES_STEREOTYPE_FULL_OUTER_JOIN: 'FULL OUTER JOIN',
	MAPPING_SOURCES_STEREOTYPE_JOIN: 'JOIN',
	MAPPING_SOURCES_STEREOTYPE_LEFT_JOIN: 'LEFT JOIN',
	MAPPING_SOURCES_STEREOTYPE_LEFT_OUTER_JOIN: 'LEFT OUTER JOIN',
	MAPPING_SOURCES_STEREOTYPE_RIGHT_JOIN: 'RIGHT JOIN',
	MAPPING_SOURCES_STEREOTYPE_RIGHT_OUTER_JOIN: 'RIGHT OUTER JOIN',
	MAPPING_SOURCE_HINTS: 'Source hints',
	MAPPING_STATUS: 'Status',
	MAPPING_STATUS_FROZEN: 'Frozen',
	MAPPING_STATUS_LOGICAL_COMPLETED: 'Logical completed',
	MAPPING_STATUS_LOGICAL_IN_PROGRESS: 'Logical in progress',
	MAPPING_STATUS_PHYSICAL_COMPLETED: 'Physical completed',
	MAPPING_STATUS_PHYSICAL_IN_PROGRESS: 'Physical in progress',
	MAPPING_STEREOTYPE: 'Stereotype',
	MAPPING_TARGET_CRITERIA: 'Target criteria',
	MAPPING_TARGET_HINTS: 'Target hints',
	MAPPING_TRUNCATE: 'Truncate',
	MAPPING_TYPE: 'Mapping type',
	MAPPING_WRITE: 'Write mapping',
	MARK_AS_DEPLOYED: 'Mark as deployed',
	MASS_DOWNLOAD: 'Generate script',
	MASS_DOWNLOAD_BUTTON: 'Generate',
	MASS_DOWNLOAD_STATUS: 'Status',
	MASS_DOWNLOAD_SORTING: 'Sorting',
	MASS_DOWNLOAD_SORTING_DEFAULT: 'Default sorting',
	MASS_DOWNLOAD_STRATEGY: 'Settings',
	MASTER_NODE_CODE: 'Master node code',
	MASTER_NODE_NAME: 'Master node name',
	MASTER_NODE_NAME_ACRONYM: 'Master node name acronym',
	MAXIMIZE: 'Maximize',
	MINIMIZE: 'Minimize',
	MESSAGE: 'Message',
	MM: 'MM',
	MONDAY: 'Monday',
	MONTHS: 'Months',
	MORE_ACTIONS: 'More actions',
	NAME: 'Name',
	NAME_CANNOT_CONTAIN_DOT: 'Name cannot have dot / period',
	NAME_NOT_SET: 'Name not set',
	NAMING: 'Naming',
	NAMING_API_COLLECTION_CODE: 'Rowset code',
	NAMING_API_NODE_CODE: 'API node code',
	NAMING_CURRENT_NODE_CODE: 'Current Node Code',
	NAMING_CURRENT_NODE_NAME: 'Current Node Name',
	NAMING_CURRENT_NODE_STEREOTYPE_CODE: 'Current Node Stereotype Code',
	NAMING_CURRENT_NODE_STEREOTYPE_NAME: 'Current Node Stereotype Name',
	NAMING_CURRENT_NODE_TYPE: 'Current node type',
	NAMING_CURRENT_NODE_TYPE_ABBR: 'Current node type abbr',
	NAMING_DATEYYYYMMDD: 'Date YYYYMMDD',
	NAMING_DDL_SCRIPT_NAME: 'DDL script name',
	NAMING_DEFAULT_MAPPING_CODE: 'Default mapping code',
	NAMING_DEFAULT_MAPPING_NAME: 'Default mapping name',
	NAMING_EXPORT_NAME: 'Export name',
	NAMING_HISTORICAL_DEFAULT_MAPPING_CODE: 'Historical default mapping code',
	NAMING_HISTORICAL_DEFAULT_MAPPING_NAME: 'Historical default mapping name',
	NAMING_HISTORICAL_TABLE_CODE: 'Historical table code',
	NAMING_HISTORICAL_TABLE_NAME: 'Historical table name',
	NAMING_INTERFACE_DEFAULT_MAPPING_CODE: 'Interface default mapping code',
	NAMING_INTERFACE_DEFAULT_MAPPING_NAME: 'Interface default mapping name',
	NAMING_MAPPING_CODE: 'Mapping code',
	NAMING_OWNER_NAME: 'Owner name',
	NAMING_PARENT_NODE_CODE: 'Parent Node Code',
	NAMING_PARENT_NODE_NAME: 'Parent Node Name',
	NAMING_PARENT_SYSTEM_CODE: 'Parent System Code',
	NAMING_PARENT_SYSTEM_NAME: 'Parent System Name',
	NAMING_PREVIEW_DDL_CURRENT_NODE_CODE: 'CRNT_NODE',
	NAMING_PREVIEW_DDL_CURRENT_NODE_NAME: 'Current Node',
	NAMING_PREVIEW_DDL_CURRENT_NODE_STEREOTYPE_CODE: 'CRNT_NODE_STEREOTYPE',
	NAMING_PREVIEW_DDL_CURRENT_NODE_STEREOTYPE_NAME: 'Current Node Stereotype',
	NAMING_PREVIEW_DDL_CURRENT_NODE_TYPE: 'TABLE',
	NAMING_PREVIEW_DDL_CURRENT_NODE_TYPE_ABBR: 'T',
	NAMING_PREVIEW_DDL_DATEYYYYMMDD: '20100423',
	NAMING_PREVIEW_DDL_MAPPING_CURRENT_NODE_TYPE: 'MAPPING',
	NAMING_PREVIEW_DDL_MAPPING_CURRENT_NODE_TYPE_ABBR: 'MAP',
	NAMING_PREVIEW_DDL_OBJECT_CODE: 'MAPPING',
	NAMING_PREVIEW_DDL_OBJECT_NAME: 'Mapping',
	NAMING_PREVIEW_DDL_OWNER_NAME: 'Owner',
	NAMING_PREVIEW_DDL_PARENT_NODE_CODE: 'PRNT_NODE',
	NAMING_PREVIEW_DDL_PARENT_NODE_NAME: 'Parent Node',
	NAMING_PREVIEW_DDL_PARENT_SYSTEM_CODE: 'PRNT_SYS',
	NAMING_PREVIEW_DDL_PARENT_SYSTEM_NAME: 'Parent System',
	NAMING_PREVIEW_PRIMARY_KEY_TABLE_CODE: 'TBL_CODE',
	NAMING_PREVIEW_PRIMARY_KEY_TABLE_NAME: 'Table name',
	NAMING_PREVIEW_PRIMARY_KEY_TABLE_STEREOTYPE_CODE: 'STEREOTYPE',
	NAMING_PRIMARY_KEY_TABLE_CODE: 'Table code',
	NAMING_PRIMARY_KEY_TABLE_STEREOTYPE_CODE: 'Table stereotype',
	NAMING_QUEUE_CODE: 'Queue code',
	NAMING_REFERENCE_TABLE_CODE: 'Reference table code',
	NAMING_REFERENCE_TABLE_NAME: 'Reference table name',
	NAMING_SEQUENCE_NAME: 'Sequence name',
	NAMING_TABLE_CODE: 'Table code',
	NAMING_TAB_ELEMENT_TYPES: 'Element types',
	NAMING_TAB_GENERATED_ELEMENTS: 'Generated elements',
	NAMING_VIEW_CODE: 'View code',
	NAVBAR_MENU_PROJECT_VIEW: 'Project view',
	NAVBAR_MENU_SETTINGS: 'Settings',
	NETWORK_ERROR: 'Network error',
	NETWORK_ERROR_MESSAGE: 'Error occured when loading data: {0}',
	NEW_PASSWORD: 'New password',
	NEW_PASSWORD_REPEAT: 'Confirm your password',
	NEW_USER: 'Create new user',
	NEXT: 'Next',
	NO: 'NO',
	NODE_OWNER: 'Owner',
	NOTHING_HERE: 'Nothing here',
	NO_DEPLOYMENT_FOR_SELECTED_ENV: 'No deployment for selected env',
	NO_FOREIGN_KEY_SELECTED: 'No Foreign key selected',
	NO_MAPPING_SELECTED: 'No mapping selected',
	NUMBER_OF_SELECTED_ELEMENTS: 'Number of selected elements',
	OBJECTS: 'Objects',
	OBJECT_SETTINGS: 'Object types',
	OBJECT_TYPE: 'Object type',
	OBJECT_TYPE_CODE: 'Object type code',
	OBJECT_TYPE_NAME: 'Object type name',
	OF: 'of',
	OK: 'OK',
	OLD_PASSWORD: 'Old password',
	ONLY_PUSHED_ENVIRONMENTS_CAN_BE_ADDED:
		'Only pushed environments can be added',
	ON_OFF: 'On/Off',
	ORDER_OF_EXECUTION: 'Order of execution',
	ORIGINAL_TABLE: 'Original table',
	OTHERS: 'Others',
	OUTPUT_FORMAT: 'Output format',
	PARALLEL: 'Parallel',
	PARALLEL_MERGE: 'Parallel merge',
	PARALLEL_START: 'Parallel start',
	PARENT: 'Parent',
	PARENT_NODE_NAME_ACRONYM: 'Parent node name acronym',
	PARITITION_STRATEGY_KEY: 'Key',
	PARITITION_STRATEGY_KEY_AND_SNAPSHOT:
		'Key  + Snapshot Date (key is select from column list)',
	PARITITION_STRATEGY_KEY_AND_SNAPSHOT_AND_DATE_EFFECTIVE:
		'Key + Snapshot Date + Snapshot Date Effective (key is select from column list)',
	PARITITION_STRATEGY_NO_PARTITION: 'No partition',
	PARITITION_STRATEGY_SNAPSHOT_DATA: 'Snapshot Date',
	PARITITION_STRATEGY_SOURCE_AND_SNAPSHOT: 'Source System + Snapshot Date',
	PARITITION_STRATEGY_SOURCE_AND_SNAPSHOT_AND_DATE_EFFECTIVE:
		'Source System + Snapshot Date + Snapshot Date Effective',
	PARITITION_STRATEGY_SOURCE_SYSTEM: 'Source System',
	PARITITION_STRATEGY_SOURCE_SYSTEM_AND_VALID_TO: 'Source System + Valid_to',
	PASSWORD_GOOD: 'Good',
	PASSWORD_STRONG: 'Strong',
	PASSWORD_VERY_STRONG: 'Very strong',
	PASSWORD_VERY_WEAK: 'Very weak',
	PASSWORD_WEAK: 'Weak',
	PATH: 'Path',
	PERMISSIONS: 'Permissions',
	PERMISSIONS_TITLE: 'Permissions',
	PERMISSIONS_USER: 'User',
	PERMISSION_CREATE_DELETE_SYSTEM: 'Create/Delete systems',
	PERMISSION_FORCE_UNLOCK: 'Force unlock',
	PERMISSION_MANAGE_LOV: 'Manage LOVs',
	PERMISSION_MANAGE_USERS: 'Manage users',
	PLAIN_TEXT: 'Plain text',
	POST_SCRIPT: 'Post-script',
	PREVIEW: 'Preview',
	PREVIEW_DLL: 'Preview',
	PREVIEW_ERROR_OBJECT_TYPE:
		'System unable to generate preview. You must select object type first.',
	PRE_SCRIPT: 'Pre-script',
	PRIMARY_KEY_NAME: 'Primary key name',
	PROCEED: 'Proceed',
	PROJECT_METADATA: 'Project metadata',
	PROJECT_VIEW: 'Project view',
	PROPERTIES: 'Properties',
	PROPERTIES_DOWN: 'Down',
	PROPERTIES_TITLE: 'Properties',
	PROPERTIES_UP: 'Up',
	PROCESSING_TASK: 'Processing task',
	PUSH: 'Push',
	PUSH_DESCRIPTION: 'Version description',
	PUSH_EXIT_EDIT_MODE: 'Exit edit mode',
	PUSH_LOCK_NODE: 'Unlock node',
	PUSH_LOCK_NODES: 'Unlock nodes',
	PUSH_MODAL_HEADER: 'Push changes',
	PUSH_TO_GIT: 'Push to git',
	PYTHON: 'Python',
	QUEUE: 'Queue',
	RAW_JSON: 'Raw',
	REFERENCE_TABLE: 'Reference table',
	REFRESH: 'Refresh',
	REGEX_VALIDATION: 'Regex validation',
	RELATION_STATUS_BROKEN: 'Broken',
	RELATION_STATUS_FK_COL_NOT_SET: 'Foreign column not set',
	RELATION_STATUS_OK: 'Ok',
	RELATION_STATUS_PK_COL_REMOVED: 'Primary key column removed',
	RELATION_STATUS_PK_SIDE_REMOVED: 'Primary key side removed',
	RELATION_TYPE_CUSTOM_LINK: 'Custom link',
	RELATION_TYPE_LINK: 'Link',
	RELATION_TYPE_LINK_COLUMN: 'Link to column',
	RELATION_TYPE_PK_FK_COLUMN: 'Foreign column',
	RELATION_TYPE_PK_FK_TABLE: 'Foreign table',
	RELEASED: 'Released',
	RELEASED_TIME: 'Released time',
	RELEASES: 'Deployment Packages',
	RELEASE_DATE: 'Release date',
	RELEASE_DETAIL: 'Deployment package detail',
	RELEASE_DRAG_DROP_HINT:
		'Drag & Drop elements from the tree in the left panel to the "Drop element" zone',
	RELEASE_NOT_FOUND: 'Release not found',
	REMOVE: 'Remove',
	REMOVE_ACRONYM: 'Remove acronym',
	REMOVE_BUSINESS_DOMAIN: 'Remove business domain',
	REMOVE_DESC: 'Are you sure you really want to delete "{0}"',
	REMOVE_DOMAIN: 'Remove domain',
	REMOVE_ELEMENT_FROM_RELEASE: 'Remove element from release',
	REMOVE_ELEMENT_FROM_RELEASE_DESCRIPTION:
		'Do you want to remove element "{0}" from release?',
	REMOVE_FILE: 'Remove file',
	REMOVE_OBJECT_SETTINGS: 'Remove object types',
	REMOVE_STEREOTYPE: 'Remove stereotype',
	REMOVE_TECH_COLUMN: 'Remove technical column',
	REMOVE_USER: 'Remove user',
	REPLACE_VERSION: 'Replace version',
	RESET: 'Reset',
	RESET_BUTTON: 'Reset',
	RESET_DESC: 'Are you sure you really want to reset password for user "{0}"',
	RESET_PASSWORD: 'Reset password',
	RESET_VERSIONS: 'Reset versions',
	RESOURCE_NAME: 'Resource name',
	RESPONSIBLE_ANALYST: 'Responsible analyst',
	RESPONSIBLE_PERSON: 'Responsible person',
	RIGHT_PORT: 'right',
	ROOT: 'Root',
	SATURDAY: 'Saturday',
	SAVE: 'Save',
	SAVE_AND_DO_NOT_UPDATE_ELEMENTS: 'Save and do not update elements',
	SAVE_AND_UPDATE_ELEMENTS: 'Save and update elements',
	SAVE_ONLY: 'Save only',
	SAVE_AND_UPDATE: 'Save & update',
	SCRIPT: 'Script',
	SCRIPT_EDITOR: 'Script editor',
	SEARCH: 'Search',
	SEARCH_ELEMENT_TYPE: 'Search element type',
	SEARCH_EMPTY: 'Nothing found',
	SEARCH_PLACEHOLDER: 'Search...',
	SELECT: 'Select',
	SELECT_ALL: 'Select all',
	SELECT_ALL_NODE: 'Select all node children ',
	SELECT_ELEMENT_TYPE_FOR_EDITING: 'Select element type for editing',
	SELECT_FILE: 'Select file',
	SELECT_MAPPING: 'Select mapping...',
	SELECT_NODE: 'Select node',
	SEQUENCE: 'Sequence',
	SEQUENCE_COLUMN: 'Sequence column',
	SETTINGS_TAB_GENERAL: 'General',
	SETTINGS_TITLE: 'Settings',
	SHOW_IMPACTED_TABLES: 'Show impacted tables',
	SHOW_IMPACTED_TABLES_AND_COLUMNS: 'Show impacted tables and columns',
	SHOW_IMPACTS: 'Show impacts',
	SINGLE_FILE: 'Single file',
	SOME_SELECTED_ELEMENTS_ARE_LOCKED_BY_OTHER_USERS_PROCEED:
		'Some selected elements are locked by other users. Unlocking will erase their autosaved working versions. Do you want to proceed?',
	SOME_SELECTED_ELEMENTS_ARE_LOCKED_BY_OTHER_USERS_SKIP:
		'Some selected elements are locked by other users and you do not have "force unlock" permission. You will not edit these locked elements during batch editing. Do you want to proceed?',
	SORT_BY_NAME_ASC: 'By Structure Name ASC',
	SORT_BY_TYPE_AND_NAME_ASC: 'By Structure Type and Name ASC',
	SOURCE_ENVIRONMENT: 'Source environment',
	SOURCE_SUBSELECT: 'Source subselect',
	SQL: 'Sql',
	STATUS: 'Status',
	STATUS_MODAL_HEADER: 'Change Status Modal - {0}',
	STATUS_MODAL_MESSAGE_1: 'You have selected a new status -',
	STATUS_MODAL_MESSAGE_2:
		"Please confirm your choice to proceed. Changing the status will update the item's progress and reflect its current state in the workflow. Ensure this change is intentional, as it may impact related processes.",
	STEP: 'Step',
	STEREOTYPE: 'Stereotype',
	STEREOTYPES: 'Stereotypes',
	STEREOTYPE_CODE: 'Stereotype code',
	STEREOTYPE_NOT_FOUND: 'Stereotype not found',
	STRUCTURED_SOURCES: 'Structured',
	SUBFLOW_EXECUTE: 'Subflow execute',
	SUBWORKFLOW: 'Subworkflow',
	SUB_STEREOTYPE: 'Sub stereotype',
	SUFFIX: 'Suffix',
	SUNDAY: 'Sunday',
	SWITCH_TO_HISTORY_TABLE: 'Switch to history table',
	SWITCH_TO_ORIGINAL_TABLE: 'Switch to original table',
	SWITCH_TO_TRANSLATION_TABLE: 'Switch to Translation Table',
	SYSTEM: 'System',
	SYSTEM_TECHNOLOGY: 'System technology',
	SYSTEM_USER: 'System user',
	TABLE: 'Table',
	Table: 'TABLE',
	Grid: 'GRID',
	Constraint: 'CONSTRAINT',
	TABLE_ALREADY_EXISTS_IN_FOLDER: 'Table already exists in folder',
	TABLE_ALREADY_EXISTS_IN_SYSTEM: 'Table already exists in system',
	TABLE_BASICS: 'Table basics',
	TABLE_CODE: 'Table code',
	TABLE_COLUMNS_CODE: 'Code',
	TABLE_COLUMNS_COMMENT: 'Comment',
	TABLE_COLUMNS_DATA_TYPE: 'Data type',
	TABLE_COLUMNS_DESCRIPTION: 'Description',
	TABLE_COLUMNS_DOMAIN: 'Domain',
	TABLE_COLUMNS_DOMAIN_PLACEHOLDER: 'Select domain...',
	TABLE_COLUMNS_EMPTY: 'There are no columns',
	TABLE_COLUMNS_FOREIGN_KEY: 'Foreign Key',
	TABLE_COLUMNS_FOREIGN_KEY_SHORT: 'FK',
	TABLE_COLUMNS_INFLAG: 'In flag',
	TABLE_COLUMNS_INFLAG_SHORT: 'IN',
	TABLE_COLUMNS_MANDATORY: 'Not null',
	TABLE_COLUMNS_MANDATORY_SHORT: 'NN',
	TABLE_COLUMNS_NAME: 'Name',
	TABLE_COLUMNS_ID: 'ID',
	TABLE_COLUMNS_OUTFLAG: 'Out flag',
	TABLE_COLUMNS_OUTFLAG_SHORT: 'OUT',
	TABLE_COLUMNS_PRIMARY_KEY_ORDER: 'Primary key order',
	TABLE_COLUMNS_PRIMARY_KEY_ORDER_SHORT: 'PK',
	TABLE_COLUMNS_STEREOTYPE: 'Stereotype',
	TABLE_COLUMNS_STEREOTYPE_PLACEHOLDER: 'Select stereotype...',
	TABLE_COLUMNS_VIRTUAL_COLUMN_SCRIPT: 'Script',
	TABLE_COMMENT: 'Table comment',
	TABLE_CONSTRAINTS_AVAILABLE_COLUMNS: 'Available columns',
	TABLE_CONSTRAINTS_CLEAR_FOREIGN_KEY: 'Clear',
	TABLE_CONSTRAINTS_CODE: 'Code',
	TABLE_CONSTRAINTS_COLUMNS: 'Columns',
	TABLE_CONSTRAINTS_COLUMNS_PLACEHOLDER: 'Select columns...',
	TABLE_CONSTRAINTS_COMMENT: 'Comment',
	TABLE_CONSTRAINTS_CONSTRAINT_NAME: 'Constraint type',
	TABLE_CONSTRAINTS_CONSTRAINT_NAME_PLACEHOLDER: 'Select constraint type...',
	TABLE_CONSTRAINTS_DESCRIPTION: 'Description',
	TABLE_CONSTRAINTS_EMPTY: 'There are no keys',
	TABLE_CONSTRAINTS_ENABLED: 'Enabled',
	TABLE_CONSTRAINTS_EXPRESSION: 'Expression',
	TABLE_CONSTRAINTS_FOREIGN_KEY: 'Foreign key',
	TABLE_CONSTRAINTS_NAME: 'Name',
	TABLE_CONSTRAINTS_NO_PRIMARY_KEYS:
		'Selected table does not have any primary key',
	TABLE_CONSTRAINTS_PHYSICAL_OPTIONS: 'Physical options',
	TABLE_CONSTRAINTS_PRIMARY_KEY: 'Primary key',
	TABLE_CONSTRAINTS_REFERENCE: 'Reference',
	TABLE_CONSTRAINTS_SCRIPT: 'Script',
	TABLE_CONSTRAINTS_SELECT_FOREIGN_KEY: 'Select column',
	TABLE_CONSTRAINTS_SELECT_TABLE: 'Select table',
	TABLE_DESCRIPTION: 'Table description',
	TABLE_EXTENDED: 'Table extended',
	TABLE_FORM_IS_INVALID: "There're some invalid values in the form",
	TABLE_FORM_IS_INVALID_TITLE: 'Unable to save table',
	TABLE_GENERAL_BUSINESS_DOMAIN: 'Business domain',
	TABLE_GENERAL_BUSINESS_METADATA: 'Business metadata',
	TABLE_GENERAL_AUTHOR: 'Author',
	TABLE_GENERAL_CODE: 'Code',
	TABLE_GENERAL_DATE: 'Date',
	TABLE_GENERAL_COMMENT: 'Comment',
	TABLE_GENERAL_DESCRIPTION: 'Description',
	TABLE_GENERAL_ENTITY_TYPE: 'Entity type',
	TABLE_GENERAL_HAS_HISTORY_TABLE: 'Has history table',
	TABLE_GENERAL_NAME: 'Name',
	TABLE_GENERAL_PROJECT_METADATA: 'Project metadata',
	TABLE_GENERAL_SEQUENCE: 'Sequence',
	TABLE_GENERAL_SEQUENCE_COLUMN: 'Sequence Column',
	TABLE_GENERAL_STEREOTYPE: 'Stereotype',
	TABLE_GENERAL_TABLE_RETENTION_STRATEGY: 'Retention strategy',
	TABLE_GENERAL_TABLE_STATUS: 'Status',
	TABLE_GENERAL_TECHNICAL_METADATA: 'Technical metadata',
	TABLE_GENERAL_VERSION: 'Version',
	TABLE_INDEXES_COLUMNS: 'Columns',
	TABLE_INDEXES_COLUMNS_PLACEHOLDER: 'Select columns...',
	TABLE_INDEXES_COMMENT: 'Comment',
	TABLE_INDEXES_DESCRIPTION: 'Description',
	TABLE_INDEXES_EMPTY: 'There are no indexes',
	TABLE_INDEXES_NAME: 'Name',
	TABLE_INDEXES_PHYSICAL_OPTIONS: 'Physical options',
	TABLE_INDEXES_SORT_TYPE: 'Sort type',
	TABLE_INDEXES_UNIQUE: 'Unique',
	TABLE_NAME: 'Table name',
	TABLE_PARTITIONING_COLUMN: 'Partitioning column',
	TABLE_PARTITIONING_STRATEGY: 'Partitioning strategy',
	TABLE_POST_TABLE_SCRIPT: 'Post-table script',
	TABLE_PRE_TABLE_SCRIPT: 'Pre-table script',
	TABLE_RETENTION_STRATEGY_INFINITE: 'Infinite',
	TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY: 'Keep full history',
	TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_AND_EOM:
		'Keep full history and EOM',
	TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_AND_EOM_LABEL: 'EOM for',
	TABLE_RETENTION_STRATEGY_KEEP_FULL_HISTORY_LABEL: 'Keep full history for',
	TABLE_SCRIPT: 'Script',
	TABLE_STATUS_FROZEN: 'Frozen',
	TABLE_STATUS_LOGICAL_COMPLETED: 'Logical completed',
	TABLE_STATUS_LOGICAL_IN_PROGRESS: 'Logical in progress',
	TABLE_STATUS_PHYSICAL_COMPLETED: 'Physical completed',
	TABLE_STATUS_PHYSICAL_IN_PROGRESS: 'Physical in progress',
	TABLE_STEREOTYPE_CODE: 'Table stereotype code',
	TABLE_STEREOTYPE_NOT_FOUND: 'Table stereotype not found',
	TAB_COLUMNS: 'Columns',
	TAB_COLUMN_MAPPINGS: 'Column mappings',
	TAB_CONSTANTS: 'Constants',
	TAB_CONSTRAINTS: 'Constraints',
	TAB_DEPLOYMENTS: 'Deployments',
	TAB_DIAGRAM: 'Diagram',
	TAB_DIAGRAMS: 'Diagrams',
	TAB_DIAGRAM_NEW: 'New Diagram',
	TAB_ENVIRONMENTS: 'Environments',
	TAB_GRAPH: 'Graph',
	TAB_INDEXES: 'Indexes',
	TAB_LOOKUPS: 'Lookups',
	TAB_MAPPINGS: 'Mappings',
	TAB_MAPPINGS_API_COLLECTION: 'Rowsets',
	TAB_OTHERS: 'Others',
	TAB_OVERVIEW: 'Overview',
	TAB_PHYSICAL_OPTIONS: 'Physical options',
	TAB_PREVIEW: 'Preview',
	TAB_SOURCES: 'Sources',
	TAB_STRUCTURE: 'Attributes',
	TAB_USERS: 'System users',
	TAB_VALIDATION: 'Validation',
	TAB_WORKFLOWS: 'Workflows',
	TAKE_OVER_VERSIONS: 'Take over versions',
	TARGET_TABLE: 'Target table',
	TECHNICAL_COLUMNS_TITLE: 'Technical columns',
	TECHNICAL_COLUMN_ADD_TO_TABLES:
		'Add this column to all tables with selected stereotypes.',
	TECHNICAL_COLUMN_UPDATE_AFFECTED_MESSAGE:
		'This change affects {0} tables and may take some time to complete. Please be patient as we process the updates. You will see a progressbar indicating the current status.',
	TECHNICAL_COLUMN_UPDATE_CONFIRM_TITLE: 'Update tables?',
	TECHNICAL_METADATA: 'Technical metadata',
	TECHNOLOGIES: 'Technologies',
	TECHNOLOGY: 'Technology',
	TECH_COLUMN_CODE: 'Code',
	TECH_COLUMN_COMMENT: 'Comment',
	TECH_COLUMN_DESCRIPTION: 'Description',
	TECH_COLUMN_DOMAIN: 'Domain',
	TECH_COLUMN_FOREIGN_KEY: 'Secondary key',
	TECH_COLUMN_NAME: 'Name',
	TECH_COLUMN_NOT_NULL: 'Not null',
	TECH_COLUMN_PRIMARY_KEY: 'Primary key',
	TECH_COLUMN_SCRIPT: 'Script',
	TECH_COLUMN_STEREOTYPE: 'Table Stereotype assignment',
	TECH_COLUMN_STEREOTYPE_COLUMN: 'Column stereotype',
	TEMPLATE: 'template',
	TEMPLATES: 'Templates',
	TEMPLATE_AUTHOR: 'Author',
	TEMPLATE_COMMENT: 'Comment',
	TEMPLATE_DATA: 'Script',
	TEMPLATE_DELETE: 'Delete template',
	TEMPLATE_DESCRIPTION: 'Description',
	TEMPLATE_ELEMENT: 'Element',
	TEMPLATE_NEW: 'Create template',
	TEMPLATE_UPDATE: 'Update template',
	TEMPLATE_VERSION: 'Version',
	TEMPLATE_VERSIONS: 'Template versions',
	TEXT_SOURCES: 'Text',
	THROW_EVENT: 'Throw event',
	THURSDAY: 'Thursday',
	TO: 'To',
	TUESDAY: 'Tuesday',
	TYPE: 'Type',
	TASK_RUNNING_WAIT: 'Task is now running, please wait..',
	TASK_FINISHED: 'Update Successful! Changes applied.',
	TASK_ERROR: 'Update Failed. Please try again.',
	TASK_START_ERROR: 'An error occurred during the start of task',
	UNDEFINED_INHERIT:
		'When undefined then it inherits value from parent folders & system',
	UNKNOWN: '(unknown)',
	UNLOCK: 'Unlock',
	UNLOCK_HEADER: 'Unlock',
	UNLOCK_MESSAGE_LOCKED_BY_USER:
		'Unlocking will erase autosaved working version made by {0}. Do you want to proceed? ',
	UNLOCK_MESSAGE_LOCKED_BY_YOU:
		'Unlocking will erase your autosaved working version. Do you want to proceed?',
	UNLOCK_NODE: 'Unlock node',
	UNLOCK_RESOURCE: 'Unlock resource',
	UNSELECT_ALL_NODE: 'Unselect all node children',
	UNSELECT_NODE: 'Unselect node',
	UNSYNCHRONIZED: 'Unsynchronized',
	UNUSED_PORT: 'Unused {0} port',
	UPDATED_AT: 'Updated at',
	UPDATED_BY: 'Updated by',
	UPDATE_ACRONYM: 'Update acronym',
	UPDATE_BUSINESS_DOMAIN: 'Update business domain',
	UPDATE_DEPENDENCY: 'Update dependency',
	UPDATE_DIAGRAM: 'Update diagram',
	UPDATE_DOMAIN: 'Update domain',
	UPDATE_IMPACTED_TABLES: 'Update impacted tables',
	UPDATE_OBJECT_SETTINGS: 'Update object types',
	UPDATE_STEREOTYPE: 'Update stereotype',
	UPDATE_TECH_COLUMN: 'Update technical column',
	UPDATE_USER: 'Update user',
	USERS: 'Users',
	USER_DEPARTMENT: 'Department',
	USER_DESC: 'Description',
	USER_LOGIN_NAME: 'Login name',
	USER_NAME: 'Name',
	USER_PASSWORD: 'Password',
	USER_PERMISSIONS: 'Permissions',
	USER_PERMISSIONS_EDIT: 'Edit permissions',
	USER_STATUS_BLOCK: 'Block user',
	USER_STATUS_BLOCK_INFO: 'Are you sure you really want to block user "{0}"',
	USER_STATUS_UNBLOCK: 'Unblock user',
	USER_STATUS_UNBLOCK_INFO:
		'Are you sure you really want to unblock user "{0}"',
	USER_SURNAME: 'Surname',
	USE_SUFFIX_ONLY_4_SYSTEM: 'Use suffix only for system',
	VALIDATION_ATTRIBUTE: 'Attribute',
	VALIDATION_DESCRIPTION: 'Description',
	VALIDATION_ELEMENT: 'Element',
	VALIDATION_FULLTEXT: 'Fulltext search',
	VALIDATION_ID: 'ID',
	VALIDATION_MESSAGE: 'Message',
	VALIDATION_NODE: 'Node',
	VALIDATION_SEVERITY: 'Severity',
	VALIDATOR_COMPARE_PASSWORDS: 'Passwords do not match',
	VALIDATOR_DUPLICATE: 'Duplicate value',
	VALIDATOR_EMAIL: 'Wrong email format',
	VALIDATION_ERRORS: 'Validation errors:',
	VALIDATOR_FLOAT_ERROR: 'Input has to be decimal',
	VALIDATOR_INTEGER_ERROR: 'Input has to be integer',
	VALIDATOR_LENGTH_MAX_ERROR: 'Maximal length of input is {0}',
	VALIDATOR_LENGTH_MIN_ERROR: 'Minimal length of input is {0}',
	VALIDATOR_LENGTH_RANGE_ERROR: 'Input has to be between {0} and {1} long',
	VALIDATOR_NUMBER_MAX_ERROR: 'Number has to be at most {0}',
	VALIDATOR_NUMBER_MINMAX_ERROR: 'Number has to be between {0} and {1}',
	VALIDATOR_NUMBER_MIN_ERROR: 'Number has to be at least {0}',
	VALIDATOR_NUMBER_RANGE_ERROR:
		'Starting value has to be less or equal to ending value',
	VALIDATOR_PASSWORD_STRENGTH:
		'Password it too weak. Increase the password length, add capital letter or number or a special character.',
	VALIDATOR_REGEX: 'Regex validation failed',
	VALIDATOR_REGEXP: 'Value does not match expression {0}',
	VALIDATOR_REQUIRED: 'Field is required',
	VALUE: 'Value',
	VERSION: 'Version',
	VERSIONS: 'Versions',
	VERSIONS_RESET: 'Versions reset',
	VERSIONS_TOOK_OVER: 'Versions took over',
	VERSION_TO_COMPARE: 'Version to compare',
	VIEW: 'View',
	VIEW_CODE: 'Code',
	VIEW_COLUMNS_CODE: 'Code',
	VIEW_COLUMNS_COMMENT: 'Comment',
	VIEW_COLUMNS_DESCRIPTION: 'Description',
	VIEW_COLUMNS_MAPPING: 'Mapping',
	VIEW_COLUMNS_NAME: 'Name',
	VIEW_COMMENT: 'Comment',
	VIEW_CRITERIA: 'Criteria script',
	VIEW_DESCRIPTION: 'Description',
	VIEW_FIELD_DESCRIPTION: '{0} description',
	VIEW_HISTORY: 'View history',
	VIEW_NAME: 'Name',
	VIEW_SOURCE: 'Source script',
	VIEW_STATUS: 'Status',
	VIEW_STATUS_FROZEN: 'Frozen',
	VIEW_STATUS_LOGICAL_COMPLETED: 'Logical completed',
	VIEW_STATUS_LOGICAL_IN_PROGRESS: 'Logical in progress',
	VIEW_STATUS_PHYSICAL_COMPLETED: 'Physical completed',
	VIEW_STATUS_PHYSICAL_IN_PROGRESS: 'Physical in progress',
	WAIT_FOR_EVENT: 'Wait for event',
	WAIT_UNTIL_FINISHED: 'Wait until finished',
	WARNING: 'Warning',
	WARNING_LOCKED_ELEMENTS_BY_OTHER_USERS:
		'Warning: Locked elements by other users',
	WEDNESDAY: 'Wednesday',
	WORKFLOW: 'Workflow',
	WORKFLOW_CODE: 'Code',
	WORKFLOW_NAME: 'Name',
	WORKFLOW_TECHNOLOGY: 'Workflow technology',
	WRITE_MAPPING: 'Write mapping',
	WRITE_MAPPINGS: 'Write mappings',
	WRONG_REGEX_SYNTAX: 'Wrong regex syntax',
	WRONG_REGEX_SYNTAX_IN_SETTINGS:
		'Wrong regex syntax in settings for this custom attribute',
	YEARS: 'Years',
	YES: 'YES',
	ZIP_OWNER_FOLDER_PER_OBJECT_TYPE: 'Zip owner folder per object type',
	ZIP_PER_OWNER_AND_OBJECT_TYPE: 'Zip per owner and object type',
	ZIP_PER_STRUCTURE: 'Separate files in Zip',
	ZOOM_IN: 'Zoom in',
	ZOOM_OUT: 'Zoom out',
	ZOOM_TO_FIT: 'Zoom to fit',
	false: 'No',
	inherit: 'Inherit',
	true: 'Yes',
	EXPORT_PNG: 'Export PNG',
	EXPORT_SVG: 'Export SVG',
}
