import { createApi } from '@reduxjs/toolkit/query/react'

import { apiRequest } from '@/endpoints/axiosInstance'
import { StructureDetailDto, TableDiagramDto } from '@/endpoints/models'
import { HttpMethod } from '@/endpoints/utils'

export const diagramApiSlice = createApi({
	reducerPath: 'diagramApiSlice',
	baseQuery: apiRequest, // Adjust the base URL based on your needs
	endpoints: (build) => ({
		getDeepDiagram: build.query<TableDiagramDto, number>({
			query: (structureId) => ({
				url: `structure/${structureId}/diagram/deep`,
				method: HttpMethod.GET,
				data: structureId,
			}),
		}),
		getNode: build.query<StructureDetailDto, { nodeId: number }>({
			query: ({ nodeId }) => ({
				url: `structure/${nodeId}`,
				method: HttpMethod.GET,
			}),
		}),
		updateNodeData: build.mutation<void, { data: any; nodeId: number }>({
			query: ({ nodeId, data }) => ({
				url: `/structure/${nodeId}/data`,
				method: HttpMethod.PUT,
				data,
			}),
		}),
	}),
})

export const {
	useGetDeepDiagramQuery,
	useGetNodeQuery,
	useUpdateNodeDataMutation,
} = diagramApiSlice
